<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
    <div class="wrap-sub-title flex">
      <div class="sub-title">연락처</div>
      <div v-if="viewCheck" class="flex-alignCenter">
        <button class="small-btn primary" @click="onClickCheck">완료</button>
        <button class="small-btn edit" @click="onClickEdit">취소</button>
      </div>
      <div v-else class="flex-alignCenter">
        <button class="small-btn edit" @click="onClickEdit">추가/수정</button>
      </div>
      
    </div>

    <table id="editTable" class="table_02" style="width: 1000px">
        <tr>
          <td class="table-field" style="width: 105px">이름</td>
          <td class="table-field" style="width: 120px">LMS 자동추가</td>
          <td class="table-field" style="width: 240px">이메일 주소</td>
          <td class="table-field" style="width: 200px">
            <span v-if="viewCheck">전화번호(‘-’ 필수입력)</span>
            <span v-else>전화번호</span>
            
          </td>
          <td class="table-field">메모</td>
          <td class="table-field" style="width: 65px">삭제</td>
        </tr>
        <tr v-if="viewCheck === false" v-for="(j) in contacts" :key="j.id" :id="j.id">
            <td class="table-data">{{ j.name || '-' }}</td>
            <td class="table-data">{{ j.lms || '-' }}</td>
            <td class="table-data">{{ j.mail || '-' }}</td>
            <td class="table-data">{{ checkTelFormat(j.tel) || '-' }}</td>
            <td class="table-data">{{ j.memo || '-' }}</td>
            <td class="table-data padding__zero">
                <button :id="j.id" class="table-inner-btn__delete" style="color: grey" @click="onClickDeleteContact($event)" :disabled="true" >✖</button>
            </td> 
        </tr>

        <tr v-else v-for="(m) in modifyContacts" :id="m.id">
            <td class="table-data padding__zero">
              <input
                v-model="m.name"
                class="input_add-contact"
                style="width: 104px"
              />
            </td>
            <td class="table-data padding__zero">
              <input
                v-model="m.lms"
                class="input_add-contact"
                style="width: 119px"
              />
            </td>
            <td class="table-data padding__zero">
              <input
                v-model="m.mail"
                class="input_add-contact"
                style="width: 239px"
              />
            </td>
            <td class="table-data padding__zero">
              <input
                v-model="m.tel"
                class="input_add-contact"
                style="width: 199px"
              />
            </td>
            <td class="table-data padding__zero">
              <input
                v-model="m.memo"
                class="input_add-contact"
                style="width: 100%"
              />
            </td>
            <td class="table-data padding__zero">
                <button :id="m.id" class="table-inner-btn__delete" style="color: red" @click="onClickDeleteContact($event)" :disabled="false" >✖</button>
            </td> 
        </tr>

        <tr v-for="(a) in addContacts" :key="a.id" :id="a.id">
            <td class="table-data padding__zero">
              <input v-model="a.name" class="input_add-contact" style="width: 104px;" placeholder="이름"/>
            </td>
            <td class="table-data padding__zero">
              <input v-model="a.lms" class="input_add-contact" style="width: 119px;" placeholder="LMS 자동추기"/>
            </td>
            <td class="table-data padding__zero">
              <input v-model="a.mail" class="input_add-contact" style="width: 239px;" placeholder="이메일 주소"/>
            </td>
            <td class="table-data padding__zero">
              <input v-model="a.tel" class="input_add-contact" style="width: 199px;" placeholder="전화번호(‘-’ 필수입력)"/>
            </td>
            <td class="table-data padding__zero">
              <input v-model="a.memo" class="input_add-contact" style="width: 100%;" placeholder="메모"/>
            </td>
            <td class="table-data padding__zero">
              <button :id="a.id" class="table-inner-btn__delete" @click="onClickDeleteAddContact($event)" :disabled="!viewCheck" >✖</button>
            </td>
        </tr>  

    </table>

    <div v-if="viewCheck" class="flex" style="width: 1000px;">
      <div class="flex-right">
        <button class="contact-btn__add" @click="onClickAdd">추가</button>
      </div>
      
    </div>

</template>
<script setup>
    import { ref, onMounted, defineProps, defineEmits } from 'vue'
    import { useStore } from 'vuex'
    import '../../../../assets/style/wrapStyle.css'
    import '../../../../assets/style/tableStyle.css'
    import '../../../../assets/style/buttonStyle.css'
    import '../../../../assets/style/titleStyle.css'
    import '../../../../assets/style/inputStyle.css'

    const store = useStore()

    const props = defineProps(['viewChecks'])
    const emit = defineEmits(['update:viewCheck'])

    const idNum = ref(0)
    const code = ref("")
    const viewCheck = ref(false)

    const modifyContacts = ref([])
    const contacts= ref([])
    const addContacts = ref([])

    const checkEmptyValue = () => {
      return addContacts.value.filter(obj => {
        const exceptId = Object.keys(obj).filter(key => key !== 'id' && key !== 'mailCheck' && key !== 'telCheck')
        return !exceptId.every(key => obj[key] === "")
      });
    }

    const checkTelFormat = (tel) => {
      const clean = tel.replace('-', '')

      if (/^02\d{7,8}$/.test(clean)) {
        return clean.replace(/(^02)(\d{3,4})(\d{4})$/, '$1-$2-$3');
      }
      
      else if (/^0\d{9}$/.test(clean)) {
        return clean.replace(/(^0\d{2})(\d{3,4})(\d{4})$/, '$1-$2-$3');
      }
      
      else if (/^01\d{9}$/.test(clean)) {
        return clean.replace(/(^01\d)(\d{4})(\d{4})$/, '$1-$2-$3')
      }
      
      return tel
    }

    onMounted(async() => {
    try{
      code.value = JSON.parse(sessionStorage.getItem('schoolCode'))
      const contact = await store.dispatch("GET_CONTACT_INFO", code.value)
      contacts.value = contact.contact
      modifyContacts.value = contact.contact
      // console.log('modifyContacts : ', modifyContacts.value)
      

      idNum.value = contacts.value[contacts.value.length -1].id + 1
      // console.log(contacts.value)
      
    }
    catch(err) {
      console.log(err)
    }
  })

    const onClickEdit = () => {
      // console.log(addContacts.value)
      if(props.viewChecks.some(check => check === true)){
        alert("수정을 마치고 진행해주세요.")
      }

      else if(viewCheck.value === true){
        if(window.confirm('수정 사항이 반영되지 않습니다.\n수정을 중단하시겠습니까?')){ 
          // console.log('before contacts: ', contacts.value)
          // modifyContacts.value = contacts.value
          // addContacts.value.splice(0, addContacts.value.length)
          // console.log('after contacts: ', contacts.value)
          // console.log('modifyContacts: ', modifyContacts.value)
          // viewCheck.value = false
          // emit('update:viewCheck', false)
          window.location.reload(true)
        }
        else{ console.log('cancel') }
      }

      else if(viewCheck.value === false){
        viewCheck.value = true
        emit('update:viewCheck', true)
        onClickAdd()
      }

      else{
        console.log("꾸짖을 갈!")
      }
      // console.log(props.viewChecks[0], props.viewChecks[1], viewCheck.value)
  }

  const onClickCheck = async() => {

      if(window.confirm('수정하시겠습니까?')){
        // console.log(checkEmptyValue())
        // console.log('modifyContacts.value',modifyContacts.value)
        
        addContacts.value = checkEmptyValue()

        const data = {
          schoolCode: code.value,
          contactList:[...modifyContacts.value, ...addContacts.value]
        }


        // 초기화 

        // const data = {
        //   schoolCode: code.value,
        //   contactList: [contacts.value[0]]
        // }

        // console.log(data)
        const res = await store.dispatch("POST_UPDATE_CONTACT_INFO", data)

        // console.log(res)
        if(res.result === true){
          alert('수정이 완료되었습니다.')
          window.location.reload(true)
        }
        else{
          alert("실패하였습니다.")
        }

      }
      else{
        console.log('수정 취소')
      }
  }


  const onClickDeleteContact = (e) => {
    if(contacts.value.length === 1){
      alert("최소 하나의 연락처는 남아야합니다.")
    }
    else{
      if(window.confirm('삭제하시겠습니까?')){
        // console.log(e.target.id)
        modifyContacts.value = modifyContacts.value.filter(item => item.id !== Number(e.target.id))
        // console.log("삭제")
      }
      else{
        console.log("삭제 취소")
      }
    }
  }

  const onClickDeleteAddContact = (e) => {
    console.log(e.target.id)
    if(addContacts.value.length === 1){
      alert("삭제할 수 없습니다.")
    }
    else{
      if(window.confirm('삭제하시겠습니까?')){
        addContacts.value = addContacts.value.filter(item => item.id !== Number(e.target.id))
        // console.log("삭제")
        
      }
      else{
        console.log("삭제 취소")
      }
    }
  }

  const onClickAdd = () => {
    addContacts.value.push({
        id: idNum.value,
        name: "",
        lms: "",
        mail: "",
        tel: "",
        memo: "",
        telCheck: false,
        mailCheck: false
    })
    idNum.value += 1
  }


</script>
<style scoped>

    
</style>