<template>
  <div v-show="store.state.messageSendListView">
    <!-- <div> -->
    <div class="flex-spaceBetween" style="width: 1200px">
      <div class="label-title wrap-sub-title">연락처 정보 확인</div>
      <div class="flex" style="width: fit-content">
        <div class="wrap-fit">
          <input
            v-model="state.mailAllCheck"
            @change="onChangeMailAll"
            type="checkbox"
            id="mail"
          />
          <label for="mail" class="label">메일 전체 선택</label>
        </div>

        <div class="wrap-checkbox wrap-fit">
          <input
            v-model="state.smsAllCheck"
            @change="onChangeSmsAll"
            type="checkbox"
            id="sms"
          />
          <label for="sms" class="label">문자 전체 선택</label>
        </div>

        <div class="wrap-checkbox wrap-fit">
          <input
            v-model="state.allCheck"
            @change="onChangeAll"
            type="checkbox"
            id="all"
          />
          <label for="all" class="label">전체 선택</label>
        </div>
      </div>
    </div>
    <table class="table_02 wrap-table_02" style="width: 1200px">
      <tr>
        <td class="table-field" style="width: 120px">이름</td>
        <td class="table-field" style="width: 280px">이메일 주소</td>
        <td class="table-field" style="width: 220px">전화번호</td>
        <td class="table-field">메모</td>
        <td class="table-field" style="width: 100px">메일 (필수)</td>
        <td class="table-field" style="width: 100px">문자 (필수)</td>
      </tr>
      <tr v-for="(j, i) in props.contact_info" :key="i">
        <td class="table-data">{{ j?.name }}</td>
        <td class="table-data">{{ j?.mail }}</td>
        <td class="table-data">{{ j?.tel }}</td>
        <td class="table-data">{{ j?.memo }}</td>
        <td class="table-data">
          <input
            type="checkbox"
            v-model="j.mailCheck"
            @change="onCheckboxMailClick(j, i)"
            :id="i"
          />
        </td>
        <td class="table-data">
          <input
            type="checkbox"
            v-model="j.telCheck"
            @change="onCheckboxMsgClick(j, i)"
            :id="i"
          />
        </td>
      </tr>
    </table>

    <div class="flex-spaceBetween" style="width: 1335px; height: fit-content">
      <!-- 메일 미리보기 -->
      <div class="wrap-mail">
        <div class="thumb-nail">메일 미리보기</div>
        <div class="wrap-mail-title">
          <div class="mail-title">
            [알공] {{ name }} 초등학교 계정발급이 완료되었습니다.
          </div>
          <div class="wrap-address">
            보낸 사람<span class="wrap-checkbox">support@dnsoft.co.kr</span>
          </div>
          <div class="wrap-address">
            <div style="width: fit-content; height: 26px; margin-right: 25px">
              받는 사람
            </div>
            <div class="wrap-recipient">
              <span v-if="props.contact_info.length === 0">-</span>
              <div
                v-else
                v-for="(c, index) in props.contact_info"
                :key="index"
                style="width: fit-content"
              >
                <div
                  :key="index"
                  v-if="c.mailCheck === true"
                  class="recipient"
                  style=""
                >
                  {{ c.mail }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-html="mailHtml"
          style="
            width: 957px;
            height: 300px;
            overflow-y: scroll;
            overflow-x: hidden;
            background-color: white;
          "
        ></div>
      </div>

      <!-- 메세지 미리보기 -->
      <div class="wrap-message">
        <div class="flex-alignEnd">
          <div>
            <textarea
              type="text"
              v-model="smsText"
              class="message"
              spellcheck="false"
            ></textarea>
          </div>
          <div class="flex">
            <div class="tail"></div>
            <div class="profile">DN</div>
          </div>
        </div>
        <button class="btn primary bottom" @click="onClickSend">
          메일 / 메세지&nbsp;&nbsp;&nbsp;전송하기
        </button>
      </div>
    </div>
  </div>
</template>
<script setup>
import {
  ref,
  defineProps,
  defineEmits,
  onBeforeMount,
  watch,
  reactive,
  onMounted,
} from "vue";
import { storeKey, useStore } from "vuex";
import { changeschoolaccountstatus } from "@/api/user-api-list";
import "../../../assets/style/buttonStyle.css";
import "../../../assets/style/inputStyle.css";
import "../../../assets/style/tableStyle.css";
import "../../../assets/style/titleStyle.css";
import "../../../assets/style/mail.css";
import "../../../assets/style/message.css";

const mailHtml = ref("");
const smsText = ref("");
const store = useStore();

const state = reactive({
  mailAllCheck: false,
  smsAllCheck: false,
  allCheck: false,
  mailArray: [],
  smsArray: [],
});
// const props = defineProps(['classInfo', 'contactInfo'])

const props = defineProps(["code", "name", "contact_info"]);
const emit = defineEmits(["contactInfo"]);

const onCheckboxMailClick = (checkbox, index) => {
  state.mailArray[index] = checkbox.mailCheck;
  console.log(state.mailArray);
  if (state.mailArray.every((v) => v === true)) {
    state.mailAllCheck = true;
  } else if (state.mailArray.some((v) => v === false)) {
    state.mailAllCheck = false;
    state.allCheck = false;
  }
};

const onCheckboxMsgClick = (checkbox, index) => {
  state.smsArray[index] = checkbox.telCheck;
  if (state.smsArray.every((v) => v === true)) {
    state.smsAllCheck = true;
  } else if (state.smsArray.some((v) => v === false)) {
    state.smsAllCheck = false;
    state.allCheck = false;
  }
};

const onChangeMailAll = () => {
  props.contact_info.map((v) => (v.mailCheck = state.mailAllCheck));
  for (let i = 0; i < state.mailArray.length; i++) {
    state.mailArray[i] = state.mailAllCheck;
  }
  console.log(state.mailArray);
  if (state.smsAllCheck === state.mailAllCheck)
    state.allCheck = state.mailAllCheck;
  else state.allCheck = false;
};

const onChangeSmsAll = () => {
  props.contact_info.map((v) => (v.telCheck = state.smsAllCheck));
  for (let i = 0; i < state.smsArray.length; i++) {
    state.smsArray[i] = state.smsAllCheck;
  }
  if (state.smsAllCheck === state.mailAllCheck)
    state.allCheck = state.smsAllCheck;
  else state.allCheck = false;
};

const onChangeAll = () => {
  state.mailAllCheck = state.allCheck;
  state.smsAllCheck = state.allCheck;

  props.contact_info.map((v) => (v.mailCheck = state.allCheck));
  props.contact_info.map((v) => (v.telCheck = state.smsAllCheck));

  for (let i = 0; i < state.mailArray.length; i++) {
    state.mailArray[i] = state.allCheck;
  }
  for (let i = 0; i < state.smsArray.length; i++) {
    state.smsArray[i] = state.smsAllCheck;
  }
};

const onClickSend = async () => {
  const mailFilter = props.contact_info.filter((v) => v.mailCheck === true);
  const smsFilter = props.contact_info.filter((v) => v.telCheck === true);

  const mail = mailFilter.map((v) => v.mail);
  const tel = smsFilter.map((v) => v.tel);

  if (mail.length === 0 || tel.length === 0) {
    alert("메일과 문자가 각각 1개 이상 선택 되어야 합니다.");
  } else {
    const mailData = {
      schoolCode: props.code,
      emailList: mail,
      content: mailHtml.value,
    };

    const smsData = {
      schoolCode: props.code,
      telList: tel,
      content: smsText.value,
    };

    // console.log('mail : ', mailData)
    // console.log('sms : ', smsData)

    const mailRes = await store.dispatch("POST_MAIL_ACCOUNT_ISSUE", mailData);
    const smsRes = await store.dispatch("POST_SMS_ACCOUNT_ISSUE", smsData);

    if (mailRes.result === true && smsRes.result === true) {
      await changeschoolaccountstatus({
        schoolCode: props.code,
        accountStatus: "발급완료",
      });
      alert(
        `전송이 완료되었습니다.\n\n- 메일: ${mail.length}건\n- 문자: ${tel.length}건`
      );
      location.reload(true);
    } else {
      alert("메일과 문자 전송에 오류가 발생하였습니다.");
    }
  }
};

const getMailTemplate = async () => {
  // console.log('watch now')
  try {
    const result = await store.dispatch("GET_MAIL_TEMPLATE", props.code);
    if (result.result === true) {
      if (result.data === "error") {
        mailHtml.value = '<div style="padding: 20px;">음서</div>';
      } else {
        mailHtml.value = result.data;
      }
    }
    // console.log(mailHtml.value)
  } catch (err) {
    console.log(err);
  }
};
const getSmsTemplate = async () => {
  // console.log('watch now')
  state.mailAllCheck = false;
  state.smsAllCheck = false;
  state.allCheck = false;

  try {
    const result = await store.dispatch("GET_SMS_TEMPLATE");
    // console.log(result)
    if (result.result === true) {
      if (result.data === "error") {
        smsText.value = "";
      } else {
        smsText.value = result.data;
      }
    }
    // console.log(smsText.value)
  } catch (err) {
    console.log(err);
  }
};

// onMounted(async() => {
//     getMailTemplate()
//     getSmsTemplate()
// })

watch(() => props.code, getMailTemplate, { immediate: true });
watch(() => props.code, getSmsTemplate, { immediate: true });
watch(
  () => props.contact_info,
  (nv) => {
    state.mailArray = nv.map((v) => v.mailCheck);
    state.smsArray = nv.map((v) => v.telCheck);
  }
);

watch(
  () => [state.mailAllCheck, state.smsAllCheck],
  ([nm, ns]) => {
    if (nm === true && ns === true) state.allCheck = true;
  }
);
</script>
<style scoped>
.label {
  /* display: inline-block; */
  margin-left: 4px;
  /* margin-top: -10px; */
  font-size: 14px;
  font-family: notosanslight;
  font-weight: bold;
}

.wrap-checkbox {
  margin-left: 25px;
}
</style>