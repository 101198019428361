<template>
    <div class="sub-title">학교정보 업로드 <span style="color: #F17D29">! 견적 명수, 개월수와 일치하는지 먼저 확인해주세요.</span></div>

    <div class="wrap-input">
        <div>
            <p class="label-title">학교 선택 &nbsp;<span>*</span></p>

            <div style="width: 250px; height: 40px;">
                <v-autocomplete
                  auto-select-first
                  append-inner-icon="mdi-magnify"
                  density="compact"
                  variant="outlined"
                  v-model="searchText"
                  :items="searchList"
                  item-text="title"
                  item-value="value"
                  color="blue"
                  placeholder="학교를 검색해주세요."
                  spellcheck="false"
                  theme="light"
                />
            </div>
        </div>
    </div>

    <p class="label-title">파일 업로드 &nbsp;<span>*</span></p>
    <div class="flex-alignCenter">
        <input ref="fileName" type="file" name="file_name" accept=".xls,.xlsx,.csv" class="input-no-icon" style="padding: 5.5px;" @change="onChangeFile($event)" placeholder="엑셀 파일을 업로드 해주세요."/>
        
        <div v-if="isChecked === 'false'" class="flex-alignCenter">
            <button class="marginLeft-30 btn edit" @click="onClickCheck">정보 확인</button>
            <div class="no-title">{{ warning }}</div>
        </div>

        <div v-else-if="isChecked === 'error'" class="flex-alignCenter">
            <button class="marginLeft-30 btn edit" @click="onClickRefresh">새로고침 요망</button>
            <div class="no-title">{{ warning }}</div>
        </div>

        
        <div v-else-if="isChecked === 'true'" class="flex-alignCenter">
            <button class="marginLeft-30 btn primary" @click="onClickCreateAccount">계정 생성</button>
            <div class="primary-title">계정 생성이 가능합니다.</div>
        </div>

    </div>
        
    <FileUploadSuccess v-if="isShow" :schoolName="schoolName" :schoolCode="searchText" :date="date"/>
    <!-- <FileUploadSuccess :schoolName="searchText" :schoolCode="selectedSchoolCode" :date="date" /> -->
</template>
  
<script setup>
    import { ref, onBeforeMount } from 'vue'
    import { useStore } from "vuex";
    import FileUploadSuccess from './FileUploadSuccessComponent.vue'
    import '../../../assets/style/popupStyle.css'
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/wrapStyle.css'
    import '../../../assets/style/buttonStyle.css'
    import '../../../assets/style/inputStyle.css'
    import '../../../assets/style/dropdownStyle.css'

    const store = useStore()

    const fileName = ref("")
    const warning = ref(null)
    const isChecked = ref("false")
    const isShow = ref(false)

    const searchList = ref([])

    const searchText = ref(null)
    const schoolName = ref("")

    const date = ref({})


    // search bar
    onBeforeMount(async () => {
        
        const { data } = await store.dispatch("GET_SEARCH_GUEST_GENERAL");
        const keys = Object.keys(data)

        keys.forEach(k => {
            if(!k.startsWith('g')){
                delete data[k]
            }
        })

        const schoolCode = Object.keys(data)
        const schoolName = Object.values(data)

        console.log('schoolCode : ', schoolCode)
        console.log('schoolName : ', schoolName)

        for(let i = 0; i<schoolCode.length; i++){
            searchList.value.push({
                value: schoolCode[i],
                title: schoolName[i]
            })
        }

        console.log('searchList : ', searchList.value)

    });


    // onClick event

    const onClickCheck = async() => {
        console.log('click 정보 확인: searchText:', searchText.value, 'fileName: ', fileName.value.files[0])
        // if문 써서 검열
        if((fileName.value && fileName.value.files.length) > 0 && (searchText.value !== null || searchText.value !== "")){
            const data = new FormData()
            data.append("file", fileName.value.files[0])
            console.log(data.get("file"))
            const res = await store.dispatch("ACCOUNT_FILE_CHECK", data)
            console.log(res)
            if(res === true){
                isChecked.value = "true"
            }
            else{
                warning.value = res
            }
        }
        else if(searchText.value === null || searchText.value === ""){
            warning.value = '학교를 입력해주세요'
        }
        else{
            warning.value = '선택된 파일이 없습니다. 파일을 업로드 해주세요.'
        }
    }

    const onClickCreateAccount = async() => {
        // props 전달해서 보여주기
        
        schoolName.value = searchList.value.find(v => v.value === searchText.value).title
        // console.log('schoolName : ', schoolName.value)
        const formData = new FormData()
        console.log('fileName : ', fileName.value.files[0])
        console.log('schoolCode : ', searchText.value)
        formData.append("file", fileName.value.files[0])
        formData.append('schoolCode', searchText.value)
        // console.log('formData : ', formData)

        const res = await store.dispatch('POST_ACCOUNT_FILE', formData)
        console.log('res : ', res)
        date.value = {...res}
        if(res.schoolStartDate && res.schoolEndDate){
            isShow.value = true
        }
        else if(typeof res === 'string'){
            console.log('error')
            isChecked.value = 'error'
            console.log('isChecked', isChecked.value)
            warning.value = res
        }
        else{
            warning.value = res
        }
    }

    const onClickRefresh = () => {
        window.location.reload(true)
    }

    // onChange event

    const onChangeFile = (e) => {
        console.log(e.target.files[0])
    }
</script>

<style scoped>
</style>