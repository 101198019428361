import {
  accountfilecheck,
  estimateRegister,
  estimatesend,
  getestimatesample,
  getschoolcontact,
  getschooldetail,
  getschoollist,
  getschoolmemo,
  getsearchlist,
  getsendestimatelist,
  getuserbytype,
  login,
  postaccountfile,
  registerschool,
  schoolclassidlist,
  schoolclasslist,
  schoolstatus,
  sendaccountguidemail,
  sendestimatemail,
  sendestimatesms,
  updatecontact,
  updateschoolmemo,
  poststopvacation,
  postpasswordcustom,
  getmailtemplate,
  getsmstemplate,
  getusername,
  getestimatelist,
  postMailAccountIssue,
  postSmsAccountIssue,
  getvactionstate,
  postTransferApply,
  getschoolcontractinfo,
  updateschoolcontract,
  updateschoolinfo,
  sendaccountguidesms
} from '@/api/user-api-list'

import {
  getSearchGuest,
  getSearchGuestYeonsu,
  getSearchGuestGeneral,
  getSchoolCreate,
  postSchoolCreate,
  postSchoolCreateContact,
  getMemo,
  postUpdateMemo,
  getContractInfo,
  postUpdateContractInfo,
  getGuestClassList,
  getGuestStudentList,
  getContactInfo,
  postUpdateContactInfo,
  getSubscription,
  postCreateGuest,
  deleteSubsciption,
  postCreateTraining,
  getCheckTrainingContact
} from '@/api/guest-api-list'

const LOGIN = async ({ commit }, data) => {
  if (!data.id || !data.password) {
    alert('로그인 정보를 입력해주세요')
    return
  }
  const res = await login(data)
  const info = res.data.data

  if (!info || info < 0) {
    alert('로그인에 실패하였습니다.')
    return
  } else {
    sessionStorage.setItem('token', info)
    sessionStorage.setItem('nav', 1)
    sessionStorage.setItem('id', data.id)
    location.href = `${window.location.origin}/user/school-manage`
  }
}

const SCHOOL_STATUS = async ({ commit }) => {
  const res = await schoolstatus()

  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const ESTIMATE_REGISTER = async ({ commit }, data) => {
  const res = await estimateRegister(data)
  if (!res.data.data) {
    return ''
  } else {
    return res.data.data
  }
}

const GET_ESTIMATE_SAMPLE = async ({ commit }, data) => {
  const res = await getestimatesample(data)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const GET_SCHOOL_CONTACT = async ({ commit }, schoolcode) => {
  const res = await getschoolcontact(schoolcode)
  if (!res.data.data) {
    return
  } else {
    return res.data.data.contact
  }
}

const SEND_ESTIMATE_MAIL = async ({ commit }, data) => {
  const res = await sendestimatemail(data)
  if (!res.data.data) {
    return { message: 'error' }
  } else {
    return res.data.data.message
  }
}

const SEND_ESTIMATE_SMS = async ({ commit }, data) => {
  const res = await sendestimatesms(data)
  if (!res.data.data) {
    return { message: 'error' }
  } else {
    return res.data.data.message
  }
}

const GET_SCHOOL_LIST = async ({ commit }) => {
  const res = await getschoollist()
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const GET_SCHOOL_DETAIL = async ({ commit }, code) => {
  const res = await getschooldetail(code)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const UPDATE_SCHOOL_MEMO = async ({ commit }, data) => {
  const res = await updateschoolmemo(data)
  if (!res.data.result) {
    return
  } else {
    return res.data.result
  }
}

const GET_SCHOOL_MEMO = async ({ commit }, code) => {
  const res = await getschoolmemo(code)
  if (!res.data.data) {
    return
  } else {
    return res.data.data.memo
  }
}

const SEND_ACCOUNT_GUIDE_MAIL = async ({ commit }, data) => {
  const res = await sendaccountguidemail(data)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const SEND_ACCOUNT_GUIDE_SMS = async ({ commit }, data) => {
  const res = await sendaccountguidesms(data)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const SEND_ESTIMATE = async ({ commit }, data) => {
  const res = await estimatesend(data)
  return res
}

const GET_ESTIMATE = async ({ commit }, code) => {
  const res = await getestimatelist(code)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const GET_SEND_ESTIMATE = async ({ commit }, code) => {
  const res = await getsendestimatelist(code)
  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const UPDATE_CONTACT = async ({ commit }, data) => {
  const res = await updatecontact(data)
  return res.data.result
}

const GET_SCHOOL_CLASS_LIST = async ({ commit }, code) => {
  const res = await schoolclasslist(code)
  if (!res.data.data) {
    return []
  } else {
    return res.data.data
  }
}

const GET_SCHOOL_CLASS_ID_LIST = async ({ commit }, data) => {
  const res = await schoolclassidlist(data)

  if (!res.data.data) {
    return []
  } else {
    return res.data.data
  }
}

const REGISTER_SCHOOL = async ({ commit }, data) => {
  const res = await registerschool(data)

  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const ACCOUNT_FILE_CHECK = async ({ commit }, data) => {
  const res = await accountfilecheck(data)

  if (!res.data.data) {
    return ''
  } else {
    return res.data.data
  }
}

const GET_SEARCH_LIST = async ({ commit }) => {
  const res = await getsearchlist()

  if (!res.data.data) {
    return []
  } else {
    return res.data.data
  }
}

const POST_ACCOUNT_FILE = async ({ commit }, data) => {
  const res = await postaccountfile(data)
  if (!res.data.data) {
    return []
  } else {
    return res.data.data
  }
}

const GET_TYPE_USERS = async ({ commit }, data) => {
  const res = await getuserbytype(data)

  if (!res.data.data) {
    return { lmsList: [], teacherList: [], studentList: [] }
  } else {
    return res.data.data
  }
}

const GET_USER_NAME = async ({ commit }) => {
  const res = await getusername()

  if (!res.data.data) {
    return ''
  } else {
    return res.data.data
  }
}

const POST_MAIL_ACCOUNT_ISSUE = async ({ commit }, data) => {
  const res = await postMailAccountIssue(data)

  if (res.data.data.message === 'good') {
    return res.data
  } else {
    return 100
  }
}

const POST_SMS_ACCOUNT_ISSUE = async ({ commit }, data) => {
  const res = await postSmsAccountIssue(data)

  if (res.data.data.message === 'good') {
    return res.data
  } else {
    return 100
  }
}

const GET_VACATION_STATE = async ({ commit }, code) => {
  const res = await getvactionstate(code)

  if (res.data.result === true) {
    return res.data
  } else {
    return 100
  }
}

const GET_SCHOOL_CONTRACT_INFO = async ({ commit }, code) => {
  const res = await getschoolcontractinfo(code)
  if (res.data.data) {
    return res.data.data
  } else {
    return false
  }
}

const UPDATE_SCHOOL_INFO = async ({ commit }, data) => {
  const res = await updateschoolinfo(data)
  if (res.data.result) {
    return true
  } else {
    return false
  }
}

const UPDATE_SCHOOL_CONTRACT_INFO = async ({ commit }, data) => {
  const res = await updateschoolcontract(data)
  if (res.data.result) {
    return true
  } else {
    return false
  }
}

// ------------------------------------------------ // 혜진님 이 밑으로만 액션 메소드 추가해주세요 저는 위에다가만 할게요 // -------------------------------------------------------------- //

const GET_SEARCH_GUEST = async ({ commit }) => {
  const res = await getSearchGuest()

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const GET_SEARCH_GUEST_YEONSU = async ({ commit }) => {
  const res = await getSearchGuestYeonsu()

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const GET_SEARCH_GUEST_GENERAL = async ({ commit }) => {
  const res = await getSearchGuestGeneral()

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const POST_STOP_VACATION = async ({ commit }, data) => {
  const res = await poststopvacation(data)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const POST_PASSWORD_CUSTOM = async ({ commit }, data) => {
  const res = await postpasswordcustom(data)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const GET_MAIL_TEMPLATE = async ({ commit }, code) => {
  const res = await getmailtemplate(code)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const GET_SMS_TEMPLATE = async ({ commit }) => {
  const res = await getsmstemplate()

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

// GUEST 사용
const GET_SCHOOL_GUEST = async ({ commit }) => {
  const res = await getSchoolCreate()

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const POST_SCHOOL_CREATE = async ({ commit }, data) => {
  const res = await postSchoolCreate(data)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const POST_SCHOOL_CREATE_CONTACT = async ({ commit }, data) => {
  const res = await postSchoolCreateContact(data)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const GET_MEMO = async ({ commit }, code) => {
  const res = await getMemo(code)

  if (!res) {
    return 100
  } else {
    return res.data
  }
}

const POST_UPDATE_MEMO = async ({ commit }, data) => {
  const res = await postUpdateMemo(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const GET_CONTRACT_INFO = async ({ commit }, code) => {
  const res = await getContractInfo(code)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data.data
    else return 200
  }
}

const POST_UPDATE_CONTRACT_INFO = async ({ commit }, data) => {
  const res = await postUpdateContractInfo(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const GET_GUEST_CLASS_INFO = async ({ commit }, code) => {
  const res = await getGuestClassList(code)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data.data
    else return 200
  }
}

const GET_GUEST_STUDENT_INFO = async ({ commit }, data) => {
  const res = await getGuestStudentList(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data.data
    else return 200
  }
}

const GET_CONTACT_INFO = async ({ commit }, code) => {
  const res = await getContactInfo(code)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data.data
    else return 200
  }
}

const POST_UPDATE_CONTACT_INFO = async ({ commit }, data) => {
  const res = await postUpdateContactInfo(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const GET_SUBSCRPTION = async ({ commit }) => {
  const res = await getSubscription()

  if (!res) {
    return 100
  } else if (!res.data.data) {
    return 300
  } else {
    if (res.data.result === true) return res.data.data
    else return 200
  }
}

const POST_CREATE_GUEST = async ({ commit }, data) => {
  const res = await postCreateGuest(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const DELETE_SUBSCRPTION = async ({ commit }, idx) => {
  const res = await deleteSubsciption(idx)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const POST_CREATE_TRAINING = async ({ commit }, data) => {
  const res = await postCreateTraining(data)

  if (!res) {
    return 100
  } else {
    if (res.data.result === true) return res.data
    else return 200
  }
}

const POST_TRANSFER_APPLY = async ({ commit }, data) => {
  const res = await postTransferApply(data)

  if (!res.data.data) {
    return
  } else {
    return res.data.data
  }
}

const GET_CHECK_TRAINING_CONTACT = async ({ commit }, code) => {
  const res = await getCheckTrainingContact(code)

  if (!res) {
    return 100
  } else if (res.data.data === undefined || res.data.data === null) {
    return 300
  } else {
    if (res.data.data === false) return false
    else if (typeof res.data.data === 'object') return res.data.data
    else return 200
  }
}

export {
  LOGIN,
  SCHOOL_STATUS,
  ESTIMATE_REGISTER,
  GET_ESTIMATE_SAMPLE,
  GET_SCHOOL_CONTACT,
  SEND_ESTIMATE_MAIL,
  SEND_ESTIMATE_SMS,
  SEND_ACCOUNT_GUIDE_MAIL,
  GET_SCHOOL_LIST,
  GET_SCHOOL_DETAIL,
  UPDATE_SCHOOL_MEMO,
  GET_SCHOOL_MEMO,
  SEND_ESTIMATE,
  GET_SEND_ESTIMATE,
  UPDATE_CONTACT,
  GET_SCHOOL_CLASS_LIST,
  GET_SCHOOL_CLASS_ID_LIST,
  REGISTER_SCHOOL,
  ACCOUNT_FILE_CHECK,
  GET_SEARCH_LIST,
  POST_ACCOUNT_FILE,
  GET_TYPE_USERS,
  POST_STOP_VACATION,
  POST_PASSWORD_CUSTOM,
  GET_MAIL_TEMPLATE,
  GET_SMS_TEMPLATE,
  GET_ESTIMATE,
  GET_USER_NAME,
  GET_SEARCH_GUEST,
  GET_SEARCH_GUEST_YEONSU,
  GET_SEARCH_GUEST_GENERAL,
  GET_SCHOOL_GUEST,
  POST_SCHOOL_CREATE,
  POST_SCHOOL_CREATE_CONTACT,
  GET_MEMO,
  POST_UPDATE_MEMO,
  GET_CONTRACT_INFO,
  POST_UPDATE_CONTRACT_INFO,
  GET_GUEST_CLASS_INFO,
  GET_GUEST_STUDENT_INFO,
  GET_CONTACT_INFO,
  POST_UPDATE_CONTACT_INFO,
  GET_SUBSCRPTION,
  POST_CREATE_GUEST,
  DELETE_SUBSCRPTION,
  POST_CREATE_TRAINING,
  POST_MAIL_ACCOUNT_ISSUE,
  POST_SMS_ACCOUNT_ISSUE,
  GET_VACATION_STATE,
  POST_TRANSFER_APPLY,
  GET_SCHOOL_CONTRACT_INFO,
  UPDATE_SCHOOL_CONTRACT_INFO,
  UPDATE_SCHOOL_INFO,
  SEND_ACCOUNT_GUIDE_SMS,
  GET_CHECK_TRAINING_CONTACT
}
