<template>
  <div
    style="
      width: 500px;
      z-index: 9999;
      position: absolute;
      font-family: notosanslight;
      font-weight: bold;
    "
  >
    <div
      id="circle1"
      v-if="accountstatus !== '안내필요'"
      class="defaultcircle"
      style="margin-left: 0"
    ></div>
    <div
      id="circle1"
      v-if="accountstatus == '안내필요'"
      class="defaultcircleon"
      style="margin-left: 0"
    ></div>
    <div
      style="
        position: absolute;
        font-size: 14px;
        margin-top: 29px;
        margin-left: -15px;
      "
    >
      안내 필요
    </div>
    <div
      v-if="accountstatus !== '정보대기'"
      id="circle2"
      class="defaultcircle"
    ></div>
    <div
      v-if="accountstatus == '정보대기'"
      id="circle2"
      class="defaultcircleon"
    ></div>
    <div
      style="
        position: absolute;
        font-size: 14px;
        margin-top: 29px;
        margin-left: 129px;
      "
    >
      정보 대기
    </div>
    <div
      v-if="accountstatus !== '발급필요'"
      id="circle3"
      class="defaultcircle"
    ></div>
    <div
      v-if="accountstatus == '발급필요'"
      id="circle3"
      class="defaultcircleon"
    ></div>
    <div
      style="
        position: absolute;
        font-size: 14px;
        margin-top: 29px;
        margin-left: 274px;
      "
    >
      발급 필요
    </div>
    <div
      v-if="accountstatus !== '발급완료'"
      id="circle4"
      class="defaultcircle"
    ></div>
    <div
      v-if="accountstatus == '발급완료'"
      id="circle4"
      class="defaultcircleon"
    ></div>
    <div
      style="
        position: absolute;
        font-size: 14px;
        margin-top: 29px;
        margin-left: 419px;
      "
    >
      발급 완료
    </div>
  </div>
  <div
    style="
      z-index: 1;
      width: 450px;
      height: 1px;
      background-color: black;
      margin-top: 12px;
      position: absolute;
    "
  ></div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    accountstatus: {
      type: String,
    },
  },
  created() {},
};
</script>

<style scoped>
.defaultcircle {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: #d9d9d9;
  float: left;
  margin-left: 120px;
  z-index: 9999;
  border: 1px solid gray;
}
.defaultcircleon {
  width: 25px;
  height: 25px;
  border-radius: 20px;
  background-color: #f17d29;
  float: left;
  margin-left: 120px;
  z-index: 9999;
  border: 1px solid gray;
}
.defaultline {
  border: 1px solid black;
  width: 100px;
  height: 1px;
  position: absolute;
  margin-top: 11px;
}
</style>