<template>
  <div>
    <div style="margin-top: 50px">
      <p style="font-family: notosans; font-size: 16px">견적 정보</p>
    </div>
    <div style="width: 1000px; margin-top: 10px">
      <table style="width: 100%; text-align: center; border-spacing: 0">
        <tr>
          <td class="tabletd">no.</td>
          <td class="tabletd">개월수</td>
          <td class="tabletd">학생수</td>
          <td class="tabletd">추가 할인액</td>
          <td class="tabletd">견적 최종가</td>
          <td class="tabletd">견적 발송 일시</td>
          <td class="tabletd">수신자 연락처 보기</td>
          <td class="tabletd tdlast">견적 담당자 ID</td>
        </tr>
        <tr v-for="(j, i) in estimatelist" :key="i">
          <td class="tabletd2">{{ i + 1 }}</td>
          <td class="tabletd2">{{ j.period }}</td>
          <td class="tabletd2">{{ j.quantity }}</td>
          <td class="tabletd2">{{ j.discount.toLocaleString() }}</td>
          <td class="tabletd2">{{ j.price.toLocaleString() }}</td>
          <td class="tabletd2">
            <p v-for="(n, k) in j.time" :key="k" style="padding: 3px">
              {{ n.time }}
            </p>
          </td>
          <td class="tabletd2">
            <div v-for="(n, k) in j.time" :key="k" style="padding: 3px">
              <p
                @click="viewcontactdetail(i * 10 + k)"
                id="contactdetail"
                style="text-decoration: underline; cursor: pointer"
              >
                상세보기
              </p>
              <div
                v-if="detailVisible === i * 10 + k"
                ref="target"
                :id="`contact${i * 10 + k}`"
                style="
                  width: 350px;
                  background-color: white;
                  position: absolute;
                  border: 1px solid rgb(180, 180, 180);
                  text-align: left;
                  border-radius: 10px;
                "
              >
                <table style="width: 100%">
                  <tr v-for="p in n.mailList" :key="p">
                    <td style="padding: 10px">{{ p.mail }} ({{ p.name }})</td>
                  </tr>
                </table>
                <table style="width: 100%; margin-top: 5px">
                  <tr v-for="p in n.telList" :key="p">
                    <td style="padding: 10px">{{ p.tel }} ({{ p.name }})</td>
                  </tr>
                </table>
              </div>
            </div>
          </td>
          <td class="tabletd2 tdlast">{{ j.manager }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

export default {
  data() {
    return {
      estimatelist: [],
    };
  },
  async created() {
    const code = this.$route.params.code;
    const res = await this.$store.dispatch("GET_SEND_ESTIMATE", code);
    const keys = Object.getOwnPropertyNames(res);

    for (let i = 0; i < keys.length; i++) {
      if (keys[i].length < 10) {
        continue;
      }
      const arr = res[keys[i]];
      arr.index = keys[i];
      this.estimatelist.push(arr);
    }
  },
  setup() {
    const target = ref(null);
    let detailVisible = ref(null);

    onClickOutside(target, () => {
      detailVisible.value = null;
    });

    return { target, detailVisible };
  },
  methods: {
    viewcontactdetail(index) {
      this.detailVisible = this.detailVisible ? null : index;
    },
  },
};
</script>

<style scoped>
.defaultbtn {
  font-size: 13px;
  font-family: notosanslight;
  font-weight: bold;
  color: white;
}

.checkbtn {
  background-color: #0e7bcb;
}

.tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 8px;
  font-size: 13px;
  background-color: rgb(240, 240, 240);
  border-right: none;
}

.tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 8px;
  font-size: 14px;
  border-right: none;
  border-top: none;
}

.tdlast {
  border-right: 1px solid #7f7f7f;
}

.contactaddbtn {
  margin-top: 10px;
  float: right;
  font-size: 15px;
  border: 1px solid #7f7f7f;
  cursor: pointer;
  font-family: notosanslight;
  font-weight: bold;
  background-color: rgb(230, 230, 230);
}
</style>