import axios from 'axios'
import { setInterceptors } from './interceptor'

const createInstanceWithAuth = () => {
  const instance = axios.create({
    baseURL: 'https://argongadmin.argong.ai'
    //baseURL: 'http://localhost:3001'
  })
  return setInterceptors(instance)
}

const instanceAuth = createInstanceWithAuth()

export { instanceAuth }
