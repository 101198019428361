<template>
  <p class="label-title" style="margin-top: 20px">학교 선택 (USER) &nbsp;<span style="color: red">*</span></p>
  <div style="width:300px; margin-top: 10px">
    <v-autocomplete @keyup.enter="search(searchtext)" auto-select-first append-inner-icon="mdi-magnify" item-props
      density="compact" menu-icon="" variant="outlined" v-model="searchtext" :items="searchList" color="blue"
      placeholder="학교를 검색해주세요." spellcheck="false"></v-autocomplete>
  </div>
  <div class="table-main">
    <table class="table_02" style="width: 1500px">
      <tr>
        <td class="table-field">No</td>
        <td class="table-field">신청 일시</td>
        <td class="table-field">처리 일시</td>
        <td class="table-field">학교이름</td>
        <td class="table-field">교사이름</td>
        <td class="table-field">연락처(선택사항)</td>
        <td class="table-field">신청사유</td>
        <td class="table-field">신청상세</td>
        <td class="table-field">상태</td>
      </tr>
      <tr v-for="(j, i) in historys" :key="i">
        <td class="table-data">{{ i + 1 }}</td>
        <td class="table-data">{{ j.applyTime }}</td>
        <td class="table-data">{{ j.processTime }}</td>
        <td class="table-data">{{ j.schoolName }}</td>
        <td :id="i + 1" class="table-data">{{ j.teacherName }}</td>
        <td class="table-data">{{ j.tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3') }}</td>
        <td class="table-data">{{ j.reason }}</td>
        <td class="table-data" style="white-space: nowrap; position: relative">
          <div ref="clickOutside">
            <button class="enroll-detail-btn__visible" @click="toggleHistory(i + 1)">
              {{ j.transferDetail.length }}&nbsp;건
            </button>
            <div v-if="historyVisible === i + 1">
              <div class="popup" @click.stop>
                <p v-for="(detail, detailIndex) in j.transferDetail" :key="detailIndex" style="text-align: left">
                  {{ detail.name }} - {{ detail.grade }}학년 {{ detail.classNum }}반 {{ detail.attendanceNumber }}번
                </p>
              </div>
            </div>
          </div>
        </td>
        <td class="table-data">{{ getStatusText(j.processStatus) }}</td>
      </tr>
    </table>
    <v-pagination v-if="historys.length > 0" @change="fetchData()" :length="pagelength" :size="20" :total-visible="10"
      style="width: 1500px; margin-top: 15px; font-size: 14px" v-model="currentPage">
    </v-pagination>
  </div>
</template>

<script setup>
import { ref, watch, onMounted } from "vue";
import { debounce } from "lodash";
import { getsearchlist, getprocessedtransferstudentlength, getprocessedtransferstudentlist } from '@/api/user-api-list'
import { onClickOutside } from "@vueuse/core";
import "../../../assets/style/tableStyle.css";
import "../../../assets/style/buttonStyle.css";
import "../../../assets/style/titleStyle.css";


const clickOutside = ref(null);
const historyVisible = ref(null);
const searchdata = ref({});
const searchList = ref([]);
const searchtext = ref(null);
const currentPage = ref(1);
const pagelength = ref(null);
const selectedSchoolCode = ref(null);
const historys = ref([]);

const getSearchList = async () => {
  const res = await getsearchlist();
  const data = res.data.data
  searchdata.value = data;
  searchList.value = Object.getOwnPropertyNames(data);
  console.log(res.data.data);
}

const search = debounce(async () => {
  let regex = /^[a-zA-Z0-9]*$/;
  const text = searchtext.value
  if (regex.test(text)) {
    selectedSchoolCode.value = text
  } else {
    selectedSchoolCode.value = searchdata.value[text];
  }

  await getPageLength();
  await getProcessedTransferStudentList({ page: 1 });
}, 500);


const getPageLength = async () => {
  try {
    const response = await getprocessedtransferstudentlength(selectedSchoolCode.value);
    pagelength.value = response.data.data;
  } catch (err) {
    console.log(err);
  }
};

const getProcessedTransferStudentList = async (data) => {
  try {
    const { page } = data;
    const apiUrl = `/schools/transfer/apply/old/${selectedSchoolCode.value}/${page}`;
    console.log("API URL:", apiUrl); // API URL 출력

    const response = await getprocessedtransferstudentlist({
      schoolCode: selectedSchoolCode.value,
      page: page
    });
    if (response.data.data && response.data.data.length > 0) {
      const dataList = response.data.data;
      historys.value = dataList;
    } else {
      historys.value = [];
    }
  } catch (error) {
    console.error("Error :", error);
  }
};

const fetchData = async () => {
  try {
    const page = currentPage.value;
    console.log(page);
    await getProcessedTransferStudentList({ page });
  } catch (error) {
    console.error("Error:", error);
  }
};

watch(currentPage, fetchData);

onMounted(() => {
  getSearchList()
});

const getStatusText = (status) => {
  return status ? "발급" : "반려";
};

const toggleHistory = (index) => {
  historyVisible.value = historyVisible.value === index ? null : index;
};

onClickOutside(clickOutside, () => {
  historyVisible.value = null;
});

</script>

<style scoped>
.popup {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 200px;
  height: auto;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 1;
  font-size: 10px;
  padding: 4px 10px 4px 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
}
</style>
