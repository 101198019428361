<template>
  <!-- 학교 정보 -->

  <div class="wrap-sub-title flex">
    <div class="sub-title">학교 정보</div>
    <div v-if="viewChecks[0]" class="flex-alignCenter">
      <button id="0" class="small-btn primary" @click="onClickSchoolInfo">
        확인
      </button>
      <button id="0" class="small-btn edit" @click="onClickEdit">취소</button>
    </div>
    <div v-else class="flex-alignCenter">
      <button id="0" class="small-btn edit" @click="onClickEdit">수정</button>
    </div>
  </div>

  <table class="table_02 session" style="width: 1000px">
    <tr>
      <td class="table-field" style="width: 220px">학교 이름</td>
      <td class="table-field" style="width: 100px">코드</td>
      <td class="table-field" style="width: 220px">구분</td>
      <td class="table-field">메모</td>
    </tr>
    <tr>
      <td class="table-data edit__off">{{ schools.schoolName }}</td>
      <td class="table-data edit__off">{{ schools.schoolCode }}</td>
      <td class="table-data edit__off">{{ schools.type }}</td>
      <td class="table-data">
        <input
          v-if="viewChecks[0]"
          v-model="memo"
          class="input_editable"
          spellcheck="false"
          type="text"
          maxlength="100"
          placeholder="최대 100자까지 입력가능합니다."
        />
        <div v-else>{{ memo }}</div>
      </td>
    </tr>
  </table>

  <!-- 계약 정보 -->

  <div class="wrap-sub-title flex">
    <div class="sub-title">계약 정보</div>
    <div v-if="viewChecks[1]" class="flex-alignCenter">
      <button id="1" class="small-btn primary" @click="onClickContractInfo">
        확인
      </button>
      <button id="1" class="small-btn edit" @click="onClickEdit">취소</button>
    </div>
    <div v-else class="flex-alignCenter">
      <button id="1" class="small-btn edit" @click="onClickEdit">수정</button>
    </div>
  </div>

  <table class="table_02 session" style="width: 800px">
    <tr>
      <td class="table-field" style="width: 180px">계약시작일</td>
      <td class="table-field" style="width: 180px">계약만료일</td>
      <td class="table-field">학생수</td>
      <td class="table-field" style="width: 160px">lms 계정개수</td>
      <td class="table-field" style="width: 160px">student 계정개수</td>
    </tr>
    <tr>
      <td class="table-data padding__zero relative">
        <input
          v-if="viewChecks[1]"
          @click="viewDate[0] = !viewDate[0]"
          v-model="contractInfo.startDate"
          class="input_editable-date"
          spellcheck="false"
          type="text"
          readonly="true"
        />
        <div v-else>{{ contractInfo.startDate || "-" }}</div>

        <div v-if="viewChecks[1]" class="calendar"></div>
        <div ref="startRef" class="wrap-datepicker" style="top: 50px">
          <DatePicker
            v-if="viewDate[0]"
            :max-date="contractInfo.endDate"
            v-model="contractInfo.startDate"
            color="blue"
          />
        </div>
      </td>

      <td class="table-data padding__zero relative">
        <input
          v-if="viewChecks[1]"
          @click="viewDate[1] = !viewDate[1]"
          v-model="contractInfo.endDate"
          class="input_editable-date"
          spellcheck="false"
          type="text"
          readonly="true"
        />
        <div v-else>{{ contractInfo.endDate || "-" }}</div>

        <div v-if="viewChecks[1]" class="calendar"></div>
        <div ref="endRef" class="wrap-datepicker" style="top: 50px">
          <DatePicker
            v-if="viewDate[1]"
            :min-date="contractInfo.startDate"
            v-model="contractInfo.endDate"
            color="blue"
          />
        </div>
      </td>
      <td class="table-data edit__off">
        {{ contractInfo.currentStudentNumber || "-" }}
      </td>
      <td class="table-data edit__off">{{ contractInfo.classCount || "-" }}</td>
      <td class="table-data edit__off">
        {{ contractInfo.contractStudentNumber || "-" }}
      </td>
    </tr>
  </table>

  <!-- 학급 정보 -->

  <div class="sub-title wrap-sub-title">학급 정보</div>

  <table class="table_02 session relative" style="width: 800px">
    <tr>
      <td class="table-field">학년</td>
      <td class="table-field">반</td>
      <td class="table-field">출판사</td>
      <td class="table-field">학생수</td>
      <td class="table-field">영어교사</td>
      <td class="table-field">담임교사</td>
      <td class="table-field">계정 관리</td>
    </tr>
    <tr v-for="(j, i) in classInfo" :key="i">
      <td class="table-data">{{ j.grade || "-" }}</td>
      <td class="table-data">{{ j.classNum || "-" }}</td>
      <td class="table-data">{{ j.publisher || "-" }}</td>
      <td class="table-data">{{ j.studentCnt || "-" }}</td>
      <td class="table-data">{{ j.engTeacher || "-" }}</td>
      <td class="table-data">{{ j.teacher || "-" }}</td>
      <td class="table-data padding__zero">
        <button
          ref="dotBtn"
          class="table-inner-btn__detail"
          @click="onClickDetailInfo(j.grade, j.classNum)"
        >
          •&nbsp;•&nbsp;•
        </button>
      </td>
    </tr>
    <ClassInfoPopup2
      v-if="$store.state.classInfoPopupView"
      :classInfo="studentInfo"
    />
    <!-- <ClassInfoPopup :style="{ top: popupTop + 'px' }" /> -->
  </table>

  <Contact :viewChecks="viewChecks" @update:viewCheck="handleViewCheck" />
</template>
<script setup>
import { ref, reactive, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { onClickOutside } from "@vueuse/core";
import { DatePicker } from "v-calendar";
import dayjs from "dayjs";
import Contact from "./GuestContactTableComponent.vue";
import ClassInfoPopup2 from "../../../popup/ClassInfoPopupComponent2.vue";
import "../../../../assets/style/wrapStyle.css";
import "../../../../assets/style/tableStyle.css";
import "../../../../assets/style/buttonStyle.css";
// import '../../../../assets/style/dropdownStyle.css'

const store = useStore();
const router = useRouter();

const code = ref("");
// console.log(code)

const schools = ref({});
const memo = ref("");
const contractInfo = ref({});
const classInfo = ref([]);
const studentInfo = ref([]);
const contactInfo = ref([]);

// 수정 flag
const viewChecks = ref([false, false]);
const viewCheck = ref(false);

// datepicker flag
const viewDate = ref([false, false]);

// onClickOutside 변수
const isOpen = ref(false);
const dropboxRef = ref(null);
const startRef = ref(null);
const endRef = ref(null);

// 왜 해놨을까
const dotBtn = ref(null);

onMounted(async () => {
  try {
    code.value = JSON.parse(sessionStorage.getItem("schoolCode"));

    const res = await store.dispatch("GET_SCHOOL_DETAIL", code.value);
    const getMemo = await store.dispatch("GET_MEMO", code.value);
    const contract = await store.dispatch("GET_CONTRACT_INFO", code.value);
    const class_info = await store.dispatch("GET_GUEST_CLASS_INFO", code.value);
    const contact = await store.dispatch("GET_CONTACT_INFO", code.value);

    console.log("getMemo: ", getMemo);

    schools.value = { ...res };
    memo.value = getMemo.data.memo;
    contractInfo.value = contract[0];
    classInfo.value = class_info;
    contactInfo.value = contact.contact;

    console.log("contractInfo: ", contractInfo.value);
    console.log("classInfo: ", classInfo.value);
    console.log("contactInfo: ", contactInfo.value);
  } catch (err) {
    console.log(err);
  }
});

onMounted(() => {
  onClickOutside(dropboxRef, () => {
    isOpen.value = false;
  });
  onClickOutside(startRef, () => {
    viewDate.value[0] = false;
  });
  onClickOutside(endRef, () => {
    viewDate.value[1] = false;
  });
});

// date click
watch(
  () => contractInfo.value.startDate,
  (newDate, oldDate) => {
    // console.log('계약 시작일 새로운 값 : ', newDate, '옛날 값 : ', oldDate)
    if (newDate === null) {
      contractInfo.value.startDate = oldDate;
      viewDate.value[0] = false;
    } else {
      contractInfo.value.startDate = dayjs(newDate).format("YYYY/MM/DD");
      viewDate.value[0] = false;
    }
  }
);

watch(
  () => contractInfo.value.endDate,
  (newDate, oldDate) => {
    // console.log('계약 만료일 새로운 값 : ', dayjs(newDate).format('YYYY/MM/DD'), '옛날 값 : ', oldDate)

    if (newDate === null) {
      contractInfo.value.endDate = oldDate;
      viewDate.value[1] = false;
    } else {
      contractInfo.value.endDate = dayjs(newDate).format("YYYY/MM/DD");
      viewDate.value[1] = false;
    }
  }
);

// onClick event
const onClickEdit = (e) => {
  const filteredArray = viewChecks.value.filter(
    (v, i) => i !== Number(e.target.id)
  );

  if (viewCheck.value === true || filteredArray[0] === true) {
    alert("수정을 마치고 진행해주세요.");
  } else if (viewChecks.value[e.target.id] === false) {
    viewChecks.value[e.target.id] = true;
  } else if (viewChecks.value[e.target.id] === true) {
    // if(window.confirm('수정 사항이 반영되지 않습니다.\n수정을 중단하시겠습니까?')){
    //   contractInfo.value = {...schools.value}
    //   viewChecks.value[e.target.id] = false
    // }

    contractInfo.value = { ...schools.value };
    viewChecks.value[e.target.id] = false;
  } else {
    console.log("cancel");
  }
};

const onClickSchoolInfo = async (e) => {
  if (window.confirm("수정하시겠습니까?")) {
    const data = { schoolCode: code.value, memo: memo.value };
    const res = await store.dispatch("POST_UPDATE_MEMO", data);

    if (res.result === true) {
      alert("메모가 수정되었습니다.");
      window.location.reload(true);
    } else if (res === 200) {
      alert("수정에 오류가 발생했습니다.");
    } else {
      console.log("오류");
    }
  } else {
    console.log("cancel");
  }
};

const onClickContractInfo = async (e) => {
  // console.log(schoolCode.value)
  if (
    !dayjs(contractInfo.value.startDate).isBefore(contractInfo.value.endDate)
  ) {
    alert("계약시작일이 계약만료일 이후에 있습니다.");
  } else {
    if (window.confirm("수정하시겠습니까?")) {
      const data = {
        schoolCode: code.value,
        contractList: [{ ...contractInfo.value }],
      };
      // console.log('data : ', data)

      const res = await store.dispatch("POST_UPDATE_CONTRACT_INFO", data);
      console.log(res);
      if (res.result === true) {
        alert("계약 정보가 수정되었습니다.");
        window.location.reload(true);
      } else if (res === 200) {
        alert("수정에 오류가 발생했습니다.");
      } else {
        console.log(res);
      }
    } else {
      console.log("cancel");
    }
  }
};

const handleViewCheck = (view) => {
  console.log("view : ", view);
  viewCheck.value = view;
};

const onClickDetailInfo = async (grade, classNum) => {
  // const student = await store.dispatch()
  console.log("click detailInfo : ", code.value, grade, classNum);
  const data = {
    code: code.value,
    grade: String(grade),
    classNum: String(classNum),
  };
  const res = await store.dispatch("GET_GUEST_STUDENT_INFO", data);

  console.log("ABC", res);
  studentInfo.value = res;
  store.commit("SET_CLASS_INFO_POPUP_VIEW", true);
};
</script>
<style scoped>
a {
  color: black;
}

.calendar {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  right: 12px;
  width: 24px;
  height: 24px;
  background-image: url("../../../../assets/image/calendar.png");
  background-size: 100%;
  z-index: -1;
}
</style>