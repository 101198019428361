<template>
    <div class="sub-title">2타입 발급 : 학생 체험계정</div>

    <div class="wrap-input">
        <div>
            <p class="label-title">학교 선택 &nbsp;<span>*</span></p>
            <div style="width: 250px; height: 40px;">
                <v-autocomplete
                    auto-select-first
                    append-inner-icon="mdi-magnify"
                    density="compact"
                    variant="outlined"
                    v-model="state.schoolName"
                    :items="searchlist"
                    item-text="title"
                    item-value="value"
                    color="blue"
                    placeholder="학교를 검색해주세요."
                    spellcheck="false"
                    theme="light"
                />
            </div>
        </div>
    </div>

    <div class="flex-spaceBetween wrap-input" style="width: 540px;">
        <div>
            <p class="label-title">학년 &nbsp;<span>*</span></p>

            <div class="wrap-dropdown">
                <div class="relative" @click="isGradeOpen = !isGradeOpen">
                    <input class="input-dropdown"  v-model="state.grade" placeholder="학년을 선택해주세요." readonly/>
                    <div class="down-arrow" :class="{'up-arrow': isGradeOpen}"></div>
                </div>
                <div v-if="isGradeOpen" ref="gradeDropboxRef" class="wrap-dropbox-content">
                    <div v-for="(g, i) in grades" :key="i" @click="onClickSelectGrade(g)">{{ g }}</div>
                </div>
            </div>
        </div>

        <div>
            <p class="label-title">출판사 &nbsp;<span>*</span></p>

            <div class="wrap-dropdown">
                <div class="relative" @click="isPublisherOpen = !isPublisherOpen">
                    <input class="input-dropdown" v-model="state.publisher" placeholder="출판사를 선택해주세요." readonly/>
                    <div class="down-arrow" :class="{'up-arrow': isPublisherOpen}"></div>
                </div>
                <div v-if="isPublisherOpen" ref="publisherDropboxRef" class="wrap-dropbox-content">
                    <div v-for="(o, i) in publishers" :key="i" @click="onClickSelectPublisher(o)">{{ o }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="flex-spaceBetween wrap-input" style="width: 540px;">
        <div>
            <p class="label-title">반 개수 &nbsp;<span>*</span></p>
            <input
                v-model="state.classCnt"
                type="text"
                class="input-no-icon"
                placeholder="숫자를 입력해주세요."
                maxlength="6"
            />
        </div>
        <div>
            <p class="label-title">반별 학생 계정수 &nbsp;<span>*</span></p>
            <input
                v-model="state.studentCnt"
                type="text"
                class="input-no-icon"
                placeholder="숫자를 입력해주세요."
                maxlength="6"
            />
        </div>

    </div>

    <div class="flex-alignEnd">
        <div class="flex-spaceBetween" style="width: 540px">
            
            <div>
                <p class="label-title">계약 시작일</p>
                <div class="flex-alignCenter relative">
                    <input
                        v-model="formattedStartDate"
                        type="text"
                        class="input-no-icon"
                        placeholder="날짜를 선택해주세요."
                        @click="isDate1Open = !isDate1Open"
                        :disabled="lockInput"
                        readonly
                    />
                    <button class="input-btn__delete_date" @click="state.startDate = ''"></button>
                    <div class="wrap-datepicker" ref="calendarRef1" style="top: 53px;">
                        <DatePicker v-if="isDate1Open" v-model="state.startDate" color="blue"/>
                    </div>
                </div>

            </div>

            <div>
                <p class="label-title">계약 종료일</p>
                <div class="flex-alignCenter relative">
                    <input
                        v-model="formattedEndDate"
                        type="text"
                        class="input-no-icon"
                        placeholder="날짜를 선택해주세요."
                        @click="isDate2Open = !isDate2Open"
                        :disabled="lockInput"
                        readonly

                    />
                    <button class="input-btn__delete_date" @click="state.endDate = ''"></button>
                    <div class="wrap-datepicker" ref="calendarRef2" style="top: 53px;">
                        <DatePicker v-if="isDate2Open" v-model="state.endDate" color="blue"/>
                    </div>
                </div>


            </div>
        </div>

        <div v-if="check === 'true'" class="flex-alignCenter next-to-space-between">
            <button class="btn primary" @click="onClickCreateAccount">계정발급 / 전송</button>
            <div class="primary-title">계정 발급이 가능합니다.</div>
        </div>

        <div v-else class="flex-alignCenter next-to-space-between">
            <button class="btn edit" @click="onClickCheck">확인</button>
            <div v-if="check === 'empty'" class="no-title">필수 항목을 모두 입력해주세요.</div>
            <div v-else-if="check === 'no_contact'" class="no-title">대표 연락처가 없습니다.</div>
        </div>



    </div>
    <div class="warning-title">* 계약기간 정보 있는 경우, 자동 입력</div>
</template>
  
<script setup>
    import { ref, watch, computed, onMounted, onBeforeMount, reactive } from 'vue'
    import { onClickOutside } from '@vueuse/core'
    import { useStore } from 'vuex'
    import { DatePicker } from 'v-calendar'
    import dayjs from 'dayjs'
    import 'v-calendar/style.css';
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/wrapStyle.css'
    import '../../../assets/style/buttonStyle.css'
    import '../../../assets/style/inputStyle.css'

    const store = useStore()

    // 변수 선언
    const state = reactive({
        schoolName: null,
        grade: "",
        publisher: "",
        classCnt: "",
        studentCnt: "",
        startDate: "",
        endDate: "",
    })

    // datepicker 변수
    const formattedStartDate = computed(() => {
        return state.startDate ? dayjs(state.startDate).format('YYYY.M.D') : "";
    });

    const formattedEndDate = computed(() => {
        return state.endDate ? dayjs(state.endDate).format('YYYY.M.D') : "";
    });

    const calendarRef1 = ref(null)
    const calendarRef2 = ref(null)
    const isDate1Open = ref(false)
    const isDate2Open = ref(false)
    const lockInput = ref(false)

    // search bar 변수
    const searchdata = ref([])
    const searchlist = ref([])

    // 유효성 검사
    const check = ref('')

    // dropbox 변수
    const gradeDropboxRef = ref(null)
    const publisherDropboxRef = ref(null)
    const isGradeOpen = ref(false);
    const isPublisherOpen = ref(false);
    
    const grades = ref(['3학년', '4학년', '5학년', '6학년']);
    const publishers = ref(['천재 교과서', '대교 교과서', '동아 교과서', 'YBM(김혜리) 교과서', 'YBM(최희경) 교과서']);

    // search bar
    onBeforeMount(async() => {
      try {
        const res1 = await store.dispatch('GET_SCHOOL_GUEST')
        const res2 = await store.dispatch('GET_SEARCH_GUEST_YEONSU')

        const filter = res1.data.filter(v => v.type === '교사 연수')
        const keys = Object.keys(res2.data)

        keys.forEach(k => {
            if(!k.startsWith('g')){
                delete res2.data[k]
            }
        })

        const schoolCode = Object.keys(res2.data)
        const schoolName = Object.values(res2.data)

        for(let i = 0; i<schoolCode.length; i++){
            searchdata.value.push({
                schoolCode: schoolCode[i],
                schoolName: schoolName[i],
                startDate: '',
                endDate: ''
            })

            searchlist.value.push({
                value: schoolCode[i],
                title: schoolName[i]
            })
        }

        searchdata.value.forEach( v => {
            const matchFilter = filter.find(f => f.schoolCode === v.schoolCode)
            if(matchFilter) {
                v.startDate = matchFilter.startDate
                v.endDate = matchFilter.endDate
            }
        })

        console.log('searchdata2:', searchdata.value)
        console.log('searchlist2:', searchlist.value)
      }
      catch(err) {
        console.log(err)
      }
    })
    // dropbox
    const onClickSelectGrade = (option) => {
        state.grade = option;
        isGradeOpen.value = false;
    };

    const onClickSelectPublisher = (option) => {
        state.publisher = option;
        isPublisherOpen.value = false;
    };

    // date
    watch(() => state.schoolName, (nv) => {
        // console.log('newValue : ', nv)
        const date = searchdata.value.find(v => v.schoolCode === nv)
        // console.log('date : ', date)
        if(date.startDate) {
            state.startDate = date.startDate
            state.endDate = date.endDate
            lockInput.value = true
            // console.log('lockInput : ', lockInput.value)
        }
        else{
            lockInput.value = false
            state.startDate = ""
            state.endDate = ""
            // console.log('lockInput : ', lockInput.value)
            console.log('nope')
        }
    })

    // focusOut

    onMounted(() => {
        onClickOutside(gradeDropboxRef, () => {
            isGradeOpen.value = false
        })
        onClickOutside(publisherDropboxRef, () => {
            isPublisherOpen.value = false
        })
        onClickOutside(calendarRef1, () => {
            isDate1Open.value = false
        })
        onClickOutside(calendarRef2, () => {
            isDate2Open.value = false
        })
    })

    // event
    //숫자 앞에 0 실시간으로 떼기
    watch(() => state.classCnt, (newDate) => {
        state.classCnt = newDate.replace(/[^0-9]|^0+(?!$)/g, '', '')
    })

    watch(() => state.studentCnt, (newDate) => {
        state.studentCnt = newDate.replace(/[^0-9]|^0+(?!$)/g, '', '')
    })



    // 검열
    const checkCondition = async() => {
        const check = Object.values(state).some(check => check === "" || check === null || check === undefined)
        const res = await store.dispatch("GET_CHECK_TRAINING_CONTACT", state.schoolName)

        if(check){
            // console.log('checkCondition empty')
            return "empty"
        }
        else if([state.classCnt, state.studentCnt].some(check => !/^\d+$/.test(check.trim()))){
            // console.log('checkCondition digit')
            return "digit"
        }
        else if([state.classCnt, state.studentCnt].some(check => /^0+$/.test(check.trim()))){
            // console.log('checkCondition zero')
            return "zero"
        }

        else if(typeof res === 'object' && !check){
            console.log('checkCondition true')
            return 'true'
        }
        else if(res === false) {
            return "no_contact"
        }
        else {
            return 'false'
        }
    }

    const onClickCheck = async() => {
        if(await checkCondition() === "empty"){ check.value = "empty" }
        else if(await checkCondition() === 'true'){ check.value = 'true' }
        else if (await checkCondition() === "no_contact"){check.value = "no_contact"}
        else { check.value = "error" }
    }


    // 계정발급 / 전송 버튼 event
    const onClickCreateAccount = async() => {
        
        if(await checkCondition() === 'true'){
            const data = {
                idx: 1,
                schoolName: searchdata.value.find(v => v.schoolCode === state.schoolName).schoolName,
                schoolCode: state.schoolName,
                grade: state.grade,
                publisher: state.publisher,
                type: "교사 연수",
                startDate: dayjs(state.startDate).format('YYYY.M.D'),
                endDate: dayjs(state.endDate).format('YYYY.M.D'),
                progressType: 2,
                amount: 0,
                classCnt: Number(state.classCnt),
                studentCnt: Number(state.studentCnt)
            }
            console.log('data:', data)

            const res = await store.dispatch("POST_CREATE_TRAINING", data)
      
            if(res.result === true){
                // 통신 성공 시
                alert("계정이 생성되었습니다.")
                console.log('show result : ', res)
                window.location.reload(true)
            }
            else if(res.result === false){ alert("계정 생성에 오류가 발생하였습니다.") }
            else { alert("시스템에 오류가 발생하였습니다.") }
        }
        else if(await checkCondition() === "empty"){ alert("필수 항목을 모두 입력해주세요.")}
        else if(await checkCondition() === "zero"){ alert("반 개수와 반별 학생 계정수에는 0만 들어갈 수 없습니다.") }
        else{ alert("예외") }
    }

</script>
  
<style scoped>
</style>