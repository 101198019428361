<template>
  <div class="wrap-view">
    <p class="title">GUEST 계정 관리 > 무료체험신청 관리</p>
    <p class="explain-title session">무료체험 학교의 계정을 발급합니다.</p>
    <div
      v-if="loading === 'true'"
      style="
        position: relative;
        width: 1500px;
        height: 537px;
        background-color: #f1f1f1;
        overflow: hidden;
      "
    >
      <div class="gradiant-walker"></div>
      <div class="skeleton">
        <!-- <Vue3Lottie 
          :animationData="Loading" 
          :height="300"
          :width="300"/> -->
      </div>
    </div>

    <div v-else-if="loading === 'error'" class="wrap-background" style="">
      <div class="sad"></div>
      <div class="error-title">
        정보를 불러오는 중에 오류가 발생하였습니다.<br /><b>페이지 새로고침</b
        >을 해주십시오.
      </div>
      <div
        style="width: fit-content; height: fit-content; cursor: pointer"
        @click="onClickRefresh"
      >
        <div class="refresh"></div>
        <div class="refresh-title">새로고침</div>
      </div>
    </div>

    <div v-else-if="loading === 'empty'" class="wrap-background" style="">
      <div class="box"></div>
      <div class="error-title" style="margin: 0; font-size: 24px">
        처리할 무료 체험신청 건이 없습니다.
      </div>
    </div>

    <div v-else>
      <div class="flex-spaceBetween" style="width: 1500px">
        <div class="label-title">
          미처리 건 : {{ appLength }}건 / 삭제 : 00건 / 처리건 : 00건
          (2024.01.01 ~ {{ dayjs(new Date()).format("YYYY.MM.DD") }} 기준)
        </div>
        <button
          class="table-inner-btn__check primary"
          style="width: 130px"
          @click="onClickAllIssuance"
        >
          무료체험 일괄 발급
        </button>
      </div>
      <table class="table_02 wrap-table_01" style="width: 1500px">
        <tr>
          <td class="table-field">No</td>
          <td class="table-field">신청 일자</td>
          <td class="table-field">학교 이름</td>
          <td class="table-field">교사이름</td>
          <td class="table-field">이메일</td>
          <td class="table-field">전화번호</td>
          <td class="table-field">학년</td>
          <td class="table-field">출판사</td>
          <td class="table-field">체험시작 일자</td>
          <td class="table-field">발급</td>
          <td class="table-field">삭제</td>
        </tr>
        <tr v-for="(j, i) in application" :key="j.idx" :id="j.idx">
          <td class="table-data">{{ i + 1 }}</td>
          <td class="table-data">{{ j.subscriptionDate }}</td>
          <td class="table-data">{{ j.schoolName }}</td>
          <td class="table-data">{{ j.teacher }}</td>
          <td class="table-data">{{ j.mail }}</td>
          <td class="table-data">{{ checkTelFormat(j.tel) }}</td>
          <td class="table-data">{{ j.grade }}</td>
          <td class="table-data">{{ j.publisher }}</td>
          <td class="table-data">{{ j.startDate }}</td>
          <td class="table-data">
            <button
              :id="j.idx"
              class="table-inner-btn__check edit"
              @click="onClickIssuance($event)"
            >
              OK
            </button>
          </td>
          <td class="table-data">
            <button
              :id="j.idx"
              class="table-inner-btn__delete"
              @click="onClickDelete($event)"
            >
              ✖
            </button>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script setup>
// import FreeTable from './FreeApplicationTableComponent.vue'
import { ref, onMounted, onBeforeMount } from "vue";
import { useStore } from "vuex";
import { Vue3Lottie } from "vue3-lottie";
import Loading from "../../../assets/image/loading.json";
import dayjs from "dayjs";
import "../../../assets/style/titleStyle.css";
import "../../../assets/style/wrapStyle.css";
import "../../../assets/style/tableStyle.css";
import "../../../assets/style/buttonStyle.css";
import { createallguest } from "../../../api/user-api-list";

const store = useStore();

const loading = ref("true");
const application = ref([]);
const appLength = ref(0);

onMounted(async () => {
  const res = await store.dispatch("GET_SUBSCRPTION");

  if (res === 100 || res === 200 || res === 300) {
    console.log("error : ", res);
    loading.value = "error";
  } else {
    appLength.value = res.targetEmailList.length;

    if (appLength.value === 0) {
      console.log(typeof appLength.value, appLength.value);
      loading.value = "empty";
    } else {
      for (let i = 0; i < appLength.value; i++) {
        let data = {
          subscriptionDate: res.targetsubscriptionDateList[i],
          idx: res.targetIdxList[i],
          mail: res.targetEmailList[i],
          grade: res.targetGradeList[i],
          tel: res.targetPhoneList[i],
          publisher: res.targetPublisherList[i],
          schoolName: res.targetSchoolList[i],
          startDate: res.targetStartList[i],
          teacher: res.targetTeacherNameList[i],
        };
        application.value.push(data);
        // console.log('data : ', i, data)
      }

      // console.log('application: ', application.value)
      loading.value = "false";
    }
  }
});

const checkTelFormat = (tel) => {
  const clean = tel.replace("-", "");

  if (/^02\d{7,8}$/.test(clean)) {
    return clean.replace(/(^02)(\d{3,4})(\d{4})$/, "$1-$2-$3");
  } else if (/^0\d{9}$/.test(clean)) {
    return clean.replace(/(^0\d{2})(\d{3,4})(\d{4})$/, "$1-$2-$3");
  } else if (/^01\d{9}$/.test(clean)) {
    return clean.replace(/(^01\d)(\d{4})(\d{4})$/, "$1-$2-$3");
  }

  return tel;
};

const onClickIssuance = async (e) => {
  if (confirm("발급하시겠습니까?")) {
    const num = Number(e.target.id);
    // console.log(num)
    const filtered = application.value.filter((v) => v.idx === num);
    console.log(filtered);
    // console.log("filtered : ", filtered)
    const data = {
      idx: filtered[0].idx,
      schoolName: filtered[0].schoolName,
      grade: filtered[0].grade,
      publisher: filtered[0].publisher,
      type: "교사 연수",
      startDate: filtered[0].startDate,
      email: filtered[0].mail,
      phonenum: filtered[0].tel,
    };

    const res = await store.dispatch("POST_CREATE_GUEST", data);

    if (res.result === true) {
      alert(
        "무료체험 계정 발급 및 전송이 완료되었습니다.\n\n메일 : 1건\n문자 : 1건"
      );
      window.location.reload(true);
    } else {
      alert("오류가 발생하였습니다.");
      // window.location.reload(true)
    }
  } else {
    console.log("발급 취소");
  }
};

const onClickDelete = async (e) => {
  // console.log(e.target.id)
  if (
    confirm(
      "삭제 시, 관리자 페이지 리스트에서만 삭제되며,\n무료체험 구글시트에는 그대로 남아있습니다."
    )
  ) {
    const num = Number(e.target.id);
    const res = await store.dispatch("DELETE_SUBSCRPTION", num);

    console.log(res);

    if (res.result === true) {
      alert("삭제되었습니다.");
      window.location.reload(true);
    } else {
      alert("오류가 발생하였습니다.");
    }
  } else {
    console.log("발급 취소");
  }
};

const onClickAllIssuance = async (e) => {
  if (confirm("일괄 발급 하시겠습니까?")) {
    // let success = 0;
    // let fail = 0;
    // const filter = application.value.map((a) => ({
    //   idx: a.idx,
    //   schoolName: a.schoolName,
    //   grade: a.grade,
    //   publisher: a.publisher,
    //   type: "교사 연수",
    //   startDate: a.startDate,
    // }));

    // const len = filter.length;

    // for (let i = 0; i < len; i++) {
    //   console.log(i, filter[i]);
    //   const res = await store.dispatch("POST_CREATE_GUEST", filter[i]);
    //   console.log(i, res);
    //   if (res.result === true) success += 1;
    //   else fail += 1;
    // }

    createallguest();

    alert(`무료체험 계정 일괄 발급 및 전송이 완료되었습니다.`);

    window.location.reload(true);
  } else {
    console.log("일괄 발급 취소");
  }
};

const onClickRefresh = () => {
  window.location.reload(true);
};
</script>

<style scoped>
@keyframes loop {
  0% {
    left: -300px;
  }
  100% {
    left: 1800px;
  }
}
.skeleton {
  position: relative;
  background-image: url("../../../assets/image/skeleton.png");
  background-size: 100%;
  width: 1500px;
  height: 537px;
  padding: 133px 0 0 0;
  background-color: transparent;
  overflow: hidden;
}

.gradiant-walker {
  position: absolute;
  width: 300px;
  height: 537px;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(217, 217, 217, 0) 0%,
    #d3d3d3 50%,
    rgba(206, 206, 206, 0) 100%
  );
  animation: loop 3s infinite;
  z-index: 0;
}

.wrap-background {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 1500px;
  height: 537px;
  font-family: notosansmedium;
  background-color: #f5f5f5;
}
.sad {
  width: 120px;
  height: 120px;
  background-image: url("../../../assets/image/error.png");
  background-size: 100%;
}

.box {
  width: 150px;
  height: 150px;
  background-image: url("../../../assets/image/box.png");
  background-size: 100%;
  margin: 0 0 45px 0;
}

.error-title {
  text-align: center;
  margin: 15px 0 80px 0;
  font-size: 22px;
  color: #616161;
}
.refresh {
  width: 60px;
  height: 60px;
  background-image: url("../../../assets/image/refresh.png");
  background-size: 100%;
}

.refresh-title {
  color: #616161;
}
</style>