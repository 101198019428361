const SET_GUSET_ADD_POPUP_VIEW = (state, value) => {
    state.guestAddPopupView = value
}

const SET_MESSAGE_SEND_POPUP_VIEW = (state, value) => {
    state.messageSendPopupView = value
}
const SET_CLASS_INFO_POPUP_VIEW = (state, value) => {
    state.classInfoPopupView = value
}

const SET_MESSAGE_SEND_LIST_VIEW = (state, value) => {
    state.messageSendListView = value
}



export { SET_GUSET_ADD_POPUP_VIEW, SET_MESSAGE_SEND_POPUP_VIEW, SET_CLASS_INFO_POPUP_VIEW, SET_MESSAGE_SEND_LIST_VIEW }