<template>
  <div>
    <div style="width: 1500px">
      <div style="margin-top: 20px"></div>
      <div>
        <div style="float: center">
          <v-pagination
            v-model="curr_page"
            :length="get_page_length()"
            :size="25"
            :total-visible="10"
          ></v-pagination>
        </div>
        <div style="float: right">
          <v-btn
            @click="registeron()"
            variant="outlined"
            class="wrap-btn"
            style="
              background-color: #747474;
              font-family: notosansregular;
              color: white;
              margin-top: 15px;
              margin-right: 10px;
            "
          >
            학교 등록
          </v-btn>
        </div>
      </div>

      <div>
        <table
          style="
            border-spacing: 0;
            border: 1px solid white;
            width: 1504px;
            margin-left: -2px;
            text-align: center;
            margin-top: 30px;
          "
        >
          <tr>
            <td id="tabletd">No</td>
            <td id="tabletd">교육청</td>
            <td id="tabletd">학교 이름</td>
            <td id="tabletd">코드</td>
            <td id="tabletd">결제 단계</td>
            <td id="tabletd">계정 발급 단계</td>
            <td id="tabletd">결제 방법</td>
            <td id="tabletd">제휴</td>
            <td id="tabletd">계약 시작일</td>
            <td id="tabletd">계약 만료일</td>
            <td id="tabletd">초기 비번</td>
            <td id="tabletd">계정 발급 안내</td>
          </tr>
          <tr v-for="(j, i) in paging(schools)[curr_page - 1]" :key="i">
            <td id="tabletd2">{{ i + 1 }}</td>
            <td id="tabletd2">{{ j.office }}</td>
            <td
              id="tabletd2"
              style="cursor: pointer; text-decoration: underline"
              @click="godetail(j.schoolCode)"
            >
              {{ j.schoolName }}
            </td>
            <td id="tabletd2">{{ j.schoolCode }}</td>
            <td id="tabletd2">{{ j.payStatus || "-" }}</td>
            <td id="tabletd2">{{ j.accountStatus || "-" }}</td>
            <td id="tabletd2">{{ j.payType || "-" }}</td>
            <td id="tabletd2">{{ j.affiliate || "-" }}</td>
            <td id="tabletd2">
              {{ getcontractstartday(j.contractYear) || "-" }}
            </td>
            <td id="tabletd2">{{ getcontractendday(j.contractYear) }}</td>
            <td id="tabletd2">{{ getsettingpw(j.contractYear) || "-" }}</td>
            <td
              @click="contacton(j.schoolCode, i)"
              id="tabletd2"
              style="
                text-decoration: underline;
                font-weight: bold;
                font-family: notosansmedium;
                cursor: pointer;
              "
            >
              메시지 전송
            </td>
          </tr>
        </table>
      </div>
    </div>
    <v-dialog
      persistent=""
      v-model="registermodal"
      width="1000"
      height="300"
      style="z-index: 99999"
    >
      <v-card width="1000" height="300">
        <div style="width: 100%; height: 60px; border-bottom: 1px solid gray">
          <p
            style="font-family: notosansregular; font-size: 18px; padding: 17px"
          >
            <b>학교 등록</b>
          </p>
          <p @click="registeroff()" class="registeroff">✕</p>
        </div>
        <div style="padding: 50px; margin-left: 30px">
          <div style="float: left; margin-right: 15px">
            <div
              style="
                font-size: 15px;
                margin-top: -5px;
                font-family: notosansregular;
              "
            >
              <b>소속 교육청</b> &nbsp;<b style="color: red">*</b>
            </div>
            <div style="width: 230px; margin-top: -18px">
              <select name="" id=""></select>
              <select required v-model="office" name="plan" class="planselect">
                <option value="" disabled selected>
                  교육청을 선택해주세요
                </option>
                <option
                  v-for="(j, i) in offices"
                  :key="i"
                  :value="j"
                  style="font-size: 16px"
                >
                  {{ j }}
                </option>
              </select>
            </div>
          </div>
          <div style="float: left; margin-left: 50px">
            <div
              style="
                font-size: 15px;
                margin-top: -5px;
                font-family: notosansregular;
              "
            >
              <b>지역명 (선택)</b>
            </div>
            <div style="width: 200px; margin-top: 5px">
              <input
                v-model="location"
                placeholder="학교이름 앞 지역명을 입력해주세요."
                class="registerinput"
                type="text"
              />
            </div>
          </div>
          <div style="float: left; margin-left: 100px">
            <div
              style="
                font-size: 15px;
                margin-top: -5px;
                font-family: notosansregular;
              "
            >
              <b>학교이름 (‘초등학교’ 제외 입력)</b>&nbsp;<b style="color: red"
                >*</b
              >
            </div>
            <div style="width: 200px; margin-top: 5px">
              <input
                v-model="schoolName"
                placeholder="학교 이름을 입력해주세요."
                class="registerinput"
                type="text"
              />
            </div>
          </div>
        </div>
        <div class="registerfooter">
          <p v-if="isDuplicated == true" class="registererrortext">
            이미 등록된 학교입니다.
          </p>
          <v-btn
            @click="registerSchool()"
            variant="outlined"
            class="registerbtn"
          >
            등록
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      style="z-index: 9898999"
      width="1000"
      height="600"
      v-model="contactmodal"
    >
      <div style="width: 100%; height: 600px; background-color: white">
        <div
          style="
            position: absolute;
            width: 100%;
            height: 60px;
            border-bottom: 1px solid gray;
          "
        >
          <p
            style="
              padding: 17px;
              font-size: 18px;
              font-weight: bold;
              font-family: notosanslight;
              float: left;
            "
          >
            학급 및 전송 연락처 선택
          </p>
          <p
            @click="contactoff()"
            style="
              font-family: notosans;
              font-size: 27px;
              padding: 8px;
              float: right;
              color: red;
              margin-right: 10px;
              cursor: pointer;
            "
          >
            ✖
          </p>
        </div>
        <v-row justify="center" style="margin-top: 120px">
          <table style="border-spacing: 0; width: 900px; text-align: center">
            <tr>
              <td id="tabletd">이름</td>
              <td id="tabletd">이메일 주소</td>
              <td id="tabletd">전화번호</td>
              <td id="tabletd">메모</td>
              <td id="tabletd">메일 (필수)</td>
              <td id="tabletd">문자 (필수)</td>
            </tr>
            <tr v-for="(j, i) in targetcontactlist" :key="i">
              <td id="tabletd2">
                {{ j.name }}
              </td>
              <td id="tabletd2">
                {{ j.mail }}
              </td>
              <td id="tabletd2">
                {{ j.tel }}
              </td>
              <td id="tabletd2">
                {{ j.memo || "-" }}
              </td>
              <td id="tabletd2">
                <input type="checkbox" v-model="j.mailCheck" />
              </td>
              <td id="tabletd2">
                <input type="checkbox" v-model="j.telCheck" />
              </td>
            </tr>
          </table>
        </v-row>
        <div
          style="
            position: absolute;
            bottom: 0;
            height: 70px;
            border-top: 1px solid gray;
            width: 100%;
          "
        >
          <div style="padding: 15px; float: right">
            <div style="float: right">
              <v-btn
                @click="checkcontact()"
                id="contactbtn"
                width="100"
                variant="outlined"
                style="
                  float: right;
                  color: white;
                  background-color: #747474;
                  font-family: notosanslight;
                  font-weight: bold;
                  font-size: 17px;
                "
                >확인</v-btn
              >
            </div>
            <div style="float: right; margin-right: 15px">
              <p
                id="contacttext"
                style="
                  font-family: notosansregular;
                  font-size: 15px;
                  margin-top: 7px;
                  color: red;
                "
              >
                {{ contacttext }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import {
  changeschoolpaystatus,
  changeschoolaccountstatus,
} from "@/api/user-api-list";

export default {
  data() {
    return {
      curr_page: 1,
      pickofficemodal: false,
      registermodal: false,
      offices: [
        "서울특별시교육청",
        "경기도교육청",
        "충청남도교육청",
        "경상남도교육청",
        "전라북도교육청",
        "경상북도교육청",
        "인천광역시교육청",
        "부산광역시교육청",
        "대구광역시교육청",
        "전라남도교육청",
        "대전광역시교육청",
        "충청북도교육청",
        "광주광역시교육청",
        "강원도교육청",
        "울산광역시교육청",
        "세종특별자치시교육청",
        "제주특별자치도교육청",
        "베트남교육청",
      ],
      office: "",
      location: "",
      schoolName: "",
      isDuplicated: false,
      contactmodal: false,
      schoolcode: "",
      schoolIndex: "",
      targetcontactlist: [],
      contactbtntext: "확인",
      contacttext: "",
    };
  },
  watch: {
    schools() {
      this.curr_page = 1;
    },
  },
  methods: {
    registeron() {
      this.registermodal = true;
    },
    registeroff() {
      this.registermodal = false;
    },
    pickofficeon() {
      this.pickofficemodal = !this.pickofficemodal;
    },
    pickoffice(str) {
      this.office = str;
      this.pickofficemodal = false;
    },
    godetail(code) {
      location.href = `${window.location.origin}/user/school-manage/${code}`;
    },
    async sendaccountguide(email, name) {
      const data = { email, name, password: "000000" };
      const res = await this.$store.dispatch("SEND_ACCOUNT_GUIDE_MAIL", data);
      if (res.message == "good") {
        alert(`${email} 으로\n계정 발급 안내 메일이 발송되었습니다.`);
      }
      if (res.message == "error") {
        alert("이메일 발송에 실패하였습니다.");
      }
    },
    get_page_length() {
      return Math.ceil(this.schools.length / 10);
    },
    paging(data) {
      const size = 10;
      const arr = [];

      for (let i = 0; i < data.length; i += size) {
        arr.push(data.slice(i, i + size));
      }

      return arr;
    },
    async registerSchool() {
      if (!this.office || !this.schoolName) {
        alert("모든 학교 정보를 올바르게 입력해주세요.");
        return;
      }

      //const schoolName = this.schoolName.replace("초등학교", "").trim();

      const data = {
        office: this.office,
        location: this.location,
        schoolName: this.schoolName,
        schoolLevel: "user",
        type: "",
      };

      if (
        window.confirm(
          `교육청: ${this.office}\n지역: ${this.location}\n학교 이름: ${this.schoolName}\n\n해당 정보로 학교를 생성하시겠습니까?`
        )
      ) {
        const res = await this.$store.dispatch("REGISTER_SCHOOL", data);

        this.schools.push({
          schoolName: res.schoolName,
          schoolCode: res.schoolCode,
          office: res.office,
          accountStatus: "안내필요",
          contractYear: [
            {
              year: 2024,
              startDate: "-",
              endDate: "-",
              settingPw: "-",
            },
          ],
        });

        this.registermodal = false;
        alert("학교 생성이 완료되었습니다.");
        return;
      } else {
        return;
      }
    },
    async contacton(schoolCode, index) {
      const res = await this.$store.dispatch("GET_SCHOOL_CONTACT", schoolCode);
      this.schoolcode = schoolCode;
      this.schoolIndex = index;
      this.targetcontactlist = res;
      this.contactmodal = true;
    },
    contactoff() {
      this.contactmodal = false;
      this.targetcontactlist = [];
    },
    getcontractstartday(data) {
      if (data.length == 0) {
        return "-";
      }
      const date = new Date();
      const year = date.getFullYear();

      for (let i = 0; i < data.length; i++) {
        if (data[i].year != year) {
          continue;
        } else {
          return data[i].startDate;
        }
      }
    },
    getcontractendday(data) {
      if (data.length == 0) {
        return "-";
      }
      const date = new Date();
      const year = date.getFullYear();

      for (let i = 0; i < data.length; i++) {
        if (data[i].year != year) {
          continue;
        } else {
          return data[i].endDate;
        }
      }
    },
    getsettingpw(data) {
      if (data.length == 0) {
        return "-";
      }
      const date = new Date();
      const year = date.getFullYear();

      for (let i = 0; i < data.length; i++) {
        if (data[i].year != year) {
          continue;
        } else {
          return data[i].settingPw;
        }
      }
    },
    async checkcontact() {
      const emailarr = this.targetcontactlist.filter(
        (item) => item.mailCheck == true
      );
      const telarr = this.targetcontactlist.filter(
        (item) => item.telCheck == true
      );

      if (!emailarr.length || !telarr.length) {
        this.contacttext = "필수 입력 항목을 선택해주세요.";
        return;
      } else {
        this.contacttext = "";
      }

      let emailList = [];
      let telList = [];

      for (let i = 0; i < emailarr.length; i++) {
        emailList.push(emailarr[i].mail);
      }

      for (let i = 0; i < telarr.length; i++) {
        telList.push(telarr[i].tel);
      }

      if (
        window.confirm(
          `메일 ${emailList.length}건 문자 ${telList.length}건\n전송하시겠습니까?`
        )
      ) {
        const res = await this.$store.dispatch("SEND_ACCOUNT_GUIDE_MAIL", {
          emailList,
        });
        const res2 = await this.$store.dispatch("SEND_ACCOUNT_GUIDE_SMS", {
          telList,
        });

        if (res.message == "good" && res2.message == "good") {
          const res3 = await changeschoolaccountstatus({
            schoolCode: this.schoolcode,
            accountStatus: "정보대기",
          });

          if (res3.data.data) {
            this.schools[this.schoolIndex].accountStatus = "정보대기";
          }

          alert(
            `메일 ${emailList.length}건 문자 ${telList.length}건\n전송되었습니다`
          );

          this.targetcontactlist = [];
          this.contactmodal = false;
          this.schoolIndex = "";
          this.schoolcode = "";
        }
      }
    },
  },
  props: {
    schools: {
      type: Array,
    },
  },
};
</script>

<style scoped>
#tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 8px;
  font-size: 13px;
  background-color: rgb(240, 240, 240);
}

#tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
}

.wrap-btn {
  position: absolute;
  right: 10px;
  top: 0px;
}

.registererrortext {
  position: absolute;
  right: 0;
  margin-right: 110px;
  font-family: notosansregular;
  color: red;
  font-size: 14px;
  margin-top: 18px;
}

.registerbtn {
  font-family: notosansregular;
  font-size: 17px;
  position: absolute;
  right: 0;
  background-color: #747474;
  color: white;
  border: 1px solid #747474;
  margin-top: 12px;
  margin-right: 15px;
}

.registerfooter {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  background-color: rgb(230, 230, 230);
}

.registerinput {
  border: 1px solid gray;
  padding: 5px;
  width: 250px;
  border-radius: 8px;
  font-size: 14px;
  padding: 10px;
  font-family: notosansregular;
}

.pickofficemodal {
  width: 230px;
  height: 130px;
  position: absolute;
  border: 1px solid gray;
  z-index: 88;
  background-color: white;
  overflow-y: scroll;
  font-family: notosansregular;
  border-radius: 5px;
}

.registeroff {
  font-weight: bold;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 25px;
  padding: 11px;
  margin-right: 10px;
  cursor: pointer;
}

.officetext {
  float: left;
  font-size: 14px;
  color: black;
  font-family: notosanslight;
  margin-top: 7px;
  margin-left: 10px;
}

.pickofficebox {
  width: 230px;
  height: 36px;
  border: 1px solid gray;
  cursor: pointer;
  border-radius: 8px;
}

.planselect {
  border: 1px solid black;
  width: 250px;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: notosanslight;
  font-weight: bold;
  font-size: 14px;
  background: url("../../../assets/image/vector.png") calc(100% - 15px) center
    no-repeat;
  background-size: 17px;
}

.planselect option[value=""][disabled] {
  display: none;
}

.planselect:required:invalid {
  color: gray;
}
</style>