<template>
   <div 
      v-if="loading === true"
      style="width: 1200px; height: 500px; padding: 100px 0; margin-top: 30px; background-color: #f5f5f5;"
    >
        <Vue3Lottie 
          :animationData="Loading" 
          :height="300"
          :width="300"/>
    </div>
    <div v-else>
        <div class="wrap-create-account">
            계정이 정상적으로 발급 완료 되었습니다.<br />
            추가 계정 세팅 및 계정 전달을 원하시면 <b>‘계정 세팅/전달’ 페이지로 이동</b>해주세요.
        </div>

        <div class="sub-title wrap-sub-title">학교 정보</div>
        <table class="table_02 session" style="width: 900px">
            <tr>
                <td class="table-field">학교 이름</td>
                <td class="table-field">코드</td>
                <td class="table-field">계약시작일</td>
                <td class="table-field">계약만료일</td>
                <td class="table-field">학생 계정수</td>
                <td class="table-field">교사 계정수</td>
                <td class="table-field">LMS 계정수</td>
            </tr>
            <tr>
                <td class="table-data edit__off">{{ school_info.schoolName || '-' }}</td>
                <td class="table-data edit__off">{{ school_info.code || '-' }}</td>
                <td class="table-data">{{ school_info.startDate || '-' }}</td>
                <td class="table-data">{{ school_info.endDate || '-' }}</td>
                <td class="table-data edit__off">{{ school_info.studentCnt || '-' }}</td>
                <td class="table-data edit__off">{{ school_info.teacherCnt || '-' }}</td>
                <td class="table-data edit__off">{{ school_info.lmsCnt || '-' }}</td>
            </tr>
        </table>

        <div class="sub-title wrap-sub-title">학급 정보</div>
        <table class="table_02 session" style="width: 700px">
            <tr>
                <td class="table-field">학년</td>
                <td class="table-field">반</td>
                <td class="table-field">출판사</td>
                <td class="table-field">학생수</td>
                <td class="table-field">영어 교사</td>
                <td class="table-field">담임 교사</td>
            </tr>
            <tr v-for="(j, i) in class_info" :key="i">
                <td class="table-data">{{ j.grade || '-' }}</td>
                <td class="table-data">{{ j.classNum || '-' }}</td>
                <td class="table-data">{{ j.publisher || '-' }}</td>
                <td class="table-data">{{ j.studentCnt || '-' }}</td>
                <td class="table-data">{{ j.engTeacher || '-' }}</td>
                <td class="table-data">{{ j.teacher || '-' }}</td>
            </tr>
        </table>

        <div class="sub-title wrap-sub-title">교사 LMS 계정</div>
        <table class="table_02 session" style="width: 900px">
            <tr>
                <td class="table-field">이름</td>
                <td class="table-field">ID</td>
                <td class="table-field">담당학급</td>
            </tr>
            <tr v-for="(j, i) in lms_account" :key="i">
                <td class="table-data">{{ j.name || '-' }}</td>
                <td class="table-data">{{ j.id || '-' }}</td>
                <td class="table-data">
                    <span v-for="(c, k) in j.classList" :key="k">
                        {{ c }}&nbsp;&nbsp;
                    </span>
                    
                </td>
            </tr>
        </table>

        <div class="sub-title wrap-sub-title">교사 알공 계정</div>
        <table class="table_02 session" style="width: 700px">
            <tr>
                <td class="table-field">학년</td>
                <td class="table-field">반</td>
                <td class="table-field">이름</td>
                <td class="table-field">ID</td>
            </tr>
            <tr v-for="(j, i) in teacher_account" :key="i">
                <td class="table-data">{{ j.grade || '-' }}</td>
                <td class="table-data">{{ j.classNum || '-' }}</td>
                <td class="table-data">{{ j.name || '-' }}</td>
                <td class="table-data">{{ j.id || '-' }}</td>
            </tr>
        </table>

        <div class="sub-title wrap-sub-title">학생 알공 계정</div>
        <table class="table_02 sesesion" style="width: 700px">
            <tr>
                <td class="table-field">학년</td>
                <td class="table-field">반</td>
                <td class="table-field">이름</td>
                <td class="table-field">ID</td>
            </tr>
            <tr v-for="(j, i) in student_account" :key="i">
                <td class="table-data">{{ j.grade || '-' }}</td>
                <td class="table-data">{{ j.classNum || '-' }}</td>
                <td class="table-data">{{ j.name || '-' }}</td>
                <td class="table-data">{{ j.id || '-' }}</td>
            </tr>
        </table>
    </div>
</template>
  
<script setup>
    import { ref, defineProps } from 'vue'
    import { useStore } from 'vuex'
    import { Vue3Lottie } from "vue3-lottie";
    import dayjs from 'dayjs'
    import Loading from "../../../assets/image/loading.json"
    import '../../../assets/style/popupStyle.css'
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/wrapStyle.css'
    import '../../../assets/style/buttonStyle.css'
    import '../../../assets/style/inputStyle.css'
    import '../../../assets/style/dropdownStyle.css'

    const store = useStore()

    const props = defineProps(['schoolName', 'schoolCode', 'date'])
    const loading = ref(true)

    console.log('props', props.schoolName, props.schoolCode, props.date)

    const school_info = ref({})
    const class_info = ref({})
    const lms_account = ref([])
    const teacher_account = ref([])
    const student_account = ref([])

    if (props.date.schoolStartDate) {
        setTimeout(async () => {
          const res1 = await store.dispatch("GET_SCHOOL_CLASS_LIST", props.schoolCode)
          console.log('res1 : ', res1)

          class_info.value = {...res1}
          console.log('class_info : ', class_info.value)

          const res2 = await store.dispatch("GET_TYPE_USERS",props.schoolCode)
          console.log('res2 : ', res2)

          lms_account.value={...res2.lmsList}
          teacher_account.value={...res2.teacherList}
          student_account.value={...res2.studentList}

          school_info.value = {
            schoolName: props.schoolName,
            code: props.schoolCode,
            startDate: dayjs(props.date.schoolStartDate).format('YYYY.M.D'),
            endDate: dayjs(props.date.schoolEndDate).format('YYYY.M.D'),
            teacherCnt: res2.teacherList.length,
            studentCnt: res2.studentList.length,
            lmsCnt: res2.lmsList.length
        }

          alert("계정이 생성되었습니다.")
          loading.value = false
        }, 2000)

      } else {
        alert("계정 생성 중 오류가 발생하였습니다.")
        loading.value = false
      }


</script>

<style scoped>
</style>