<template>
  <div class="table-main session" style="margin-top: 10px">
    <table class="table_02" style="width: 1500px">
      <tr>
        <td class="table-field">No</td>
        <td class="table-field">신청 일시</td>
        <td class="table-field">학교이름</td>
        <td class="table-field">교사이름</td>
        <td class="table-field">연락처(선택사항)</td>
        <td class="table-field">신청사유</td>
        <td class="table-field">신청 상세</td>
        <td class="table-field">기존 처리 계정 수</td>
        <td class="table-field">발급</td>
        <td class="table-field">반려</td>
      </tr>
      <tr v-for="(j, i) in schools" :key="i">
        <td class="table-data">{{ i + 1 }}</td>
        <td class="table-data">{{ j.applyTime }}</td>
        <td class="table-data">{{ j.schoolName }}</td>
        <td :id="i + 1" class="table-data">{{ j.teacherName }}</td>
        <td class="table-data">
          {{ j.tel.replace(/(\d{3})(\d{4})(\d{4})/, "$1-$2-$3") }}
        </td>
        <td class="table-data">{{ j.reason }}</td>
        <td class="table-data" style="white-space: nowrap; position: relative">
          <div ref="clickOutside">
            <button
              class="enroll-detail-btn__visible"
              @click="toggleDetail(i + 1)"
            >
              {{ j.transferDetail.length }}&nbsp;건
            </button>
            <div v-if="detailVisible === i + 1">
              <div class="popup" @click.stop>
                <p
                  v-for="(detail, detailIndex) in j.transferDetail"
                  :key="detailIndex"
                  style="text-align: left"
                >
                  {{ detail.name }} - {{ detail.grade }}학년
                  {{ detail.classNum }}반 {{ detail.attendanceNumber }}번
                </p>
              </div>
            </div>
          </div>
        </td>
        <td class="table-data">{{ j.historyLength }}건</td>
        <td class="table-data">
          <button
            class="table-inner-btn__check edit"
            @click="onClickIssue(j._id, j.transferDetail.length, j.tel)"
          >
            OK
          </button>
        </td>
        <td class="table-data">
          <button class="table-inner-btn__delete" @click="openModal">✖</button>
          <div v-if="modalVisible" class="background">
            <div class="popWapper__default">
              <div class="denyModal">
                <div class="issueModalTitle">반려 사유를 적어주세요.</div>
                <hr />
                <div class="text-input-container">
                  <textarea
                    v-model="returnValue"
                    placeholder="텍스트를 입력하세요"
                  ></textarea>
                </div>
                <div class="button-container">
                  <button @click="handleConfirm(j._id, j.tel)" class="btn edit">
                    확인
                  </button>
                </div>
              </div>
            </div>
          </div>
        </td>
      </tr>
      <tr v-for="index in 5 - schools.length" :key="'empty-' + index">
        <template v-for="n in 10" :key="n">
          <td class="table-data"></td>
        </template>
      </tr>
    </table>
    <v-pagination
      @change="fetchData()"
      :length="pagelength"
      :size="20"
      :total-visible="10"
      style="width: 1500px; margin-top: 15px; font-size: 14px"
      v-model="currentPage"
    ></v-pagination>
  </div>
</template>

<script setup>
import { ref, onMounted, defineProps, watch } from "vue";
import { useStore } from "vuex";
import {
  gettransferstudentlength,
  gettransferstudentlist,
  getprocesshistory,
  transferapplysms,
} from "@/api/user-api-list";
import { onClickOutside } from "@vueuse/core";
import "../../../assets/style/tableStyle.css";
import "../../../assets/style/buttonStyle.css";
import "../../../assets/style/titleStyle.css";

// 신청 상세 팝업 뜨게 하기
const store = useStore();
const detailVisible = ref(null);
const clickOutside = ref(null);
const modalVisible = ref(false);
const returnValue = ref("");
const pagelength = ref(null);
const currentPage = ref(1);
const schools = ref([]);

const toggleDetail = (index) => {
  detailVisible.value = detailVisible.value === index ? null : index;
};

onClickOutside(clickOutside, () => {
  detailVisible.value = null;
});

const openModal = () => {
  modalVisible.value = true;
};

const closeModal = () => {
  modalVisible.value = false;
};

const getPageLength = async () => {
  try {
    const response = await gettransferstudentlength();
    if (!response.data.data) {
      pagelength.value = 1;
    } else {
      pagelength.value = response.data.data;
    }
  } catch (err) {
    console.log(err);
  }
};

// 신청 리스트
const getTransferStudentsList = async (page) => {
  try {
    const response = await gettransferstudentlist(page);
    if (response.data.data && response.data.data.length > 0) {
      const data = response.data.data;
      for (let i = 0; i < data.length; i++) {
        const res = await getprocesshistory(data[i].schoolCode);
        data[i].historyLength = res.data.data;
      }
      schools.value = data;
    } else {
      schools.value = [];
    }
  } catch (error) {
    console.error("Error fetching transfer student list:", error);
  }
};

//발급 기능
const onClickIssue = async (_id, count, tel) => {
  try {
    const data = {
      _id,
      processStatus: true,
      refuseReason: "",
    };

    const response = await store.dispatch("POST_TRANSFER_APPLY", data);

    if (response) {
      const res = await transferapplysms({ phonenum: tel, status: true });
      if (res.data.data.message == "good") {
        alert(
          `계정 ${count}개의 발급 및 전송이 완료되었습니다. \n\n 문자 건수: 1건 \n LMS 건수: 1건`
        );
        window.location.reload(true);
      } else {
        alert(
          "API 요청이 성공했지만 서버로부터 올바른 응답을 받지 못했습니다."
        );
      }
    }
  } catch (err) {
    alert("API 요청이 실패했습니다.");
  }
};

// 반려 구현
const handleConfirm = async (_id, phonenum) => {
  try {
    const refuseReason = returnValue.value.trim();

    if (refuseReason === "" || refuseReason === "텍스트를 입력하세요") {
      alert("반려 사유 입력이 되지 않았습니다.");
      return;
    }

    const data = {
      _id,
      processStatus: false,
      refuseReason: refuseReason,
    };

    // if (refuseReason.trim() !== "") {
    //   data.processStatus = true;
    // }
    const res = await store.dispatch("POST_TRANSFER_APPLY", data);
    console.log("서버 응답:", res);

    if (!res) {
      const response = await transferapplysms({ phonenum, status: false });
      if (response.data.data.message == "good") {
        alert(
          "반려 사유를 전달 완료했습니다.\n\n문자 건수 : 1건\nLMS 알림 : 1건"
        );
        closeModal();
        window.location.reload(true);
      }
    } else {
      alert("서버에 문제가 발생하여 반려 사유를 전달하지 못했습니다.");
    }
  } catch (err) {
    console.log(err);
    alert("반려 처리 중 오류가 발생했습니다. 잠시 후 다시 시도해주세요.");
  }
};

const fetchData = async () => {
  try {
    const page = currentPage.value;
    getTransferStudentsList(page);
  } catch (error) {
    console.error("Error:", error);
  }
};

watch(currentPage, fetchData);

onMounted(() => {
  getPageLength();
  getTransferStudentsList(1);
});
</script>

<style scoped>
.popup {
  position: absolute;
  top: 80%;
  left: 50%;
  width: 200px;
  height: auto;
  transform: translateX(-50%);
  background-color: #fff;
  z-index: 1;
  font-size: 13px;
  padding: 4px 10px 4px 20px;
  border-radius: 5px;
  box-shadow: 0 0 5px gray;
}

hr {
  border: 1px solid #ddd;
  margin: 15px 0;
}

.text-input-container {
  margin-bottom: 30px;
  border: 2px solid #cccccc;
  border-radius: 5px;
  padding: 10px;
  height: 140px;
  align-items: center;
}

textarea {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  resize: none;
  border: none;
  outline: none;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}
</style>
