<template>
  <GuestAddPopup :school="propsSchools" />
  <div class="marginTop-70" style="width: 1500px">
    <div>
      <v-row class="flex-spaceBetween flex-alignEnd" style="width: 1501px">
        <v-tabs v-model="tab" align-tabs="left" color="black">
          <v-tab @click="goall()" :value="1" class="tabstyle">전체 </v-tab>
          <v-tab @click="onClickFree" :value="2" class="tabstyle">무료 체험</v-tab>
          <v-tab @click="onClickTeacher" :value="3" class="tabstyle">교사 연수</v-tab>
          <v-tab :value="10" class="tabstyle">검색 결과</v-tab>
        </v-tabs>

        <div class="searchMain">
          <div class="count-search">{{ searchResult.length }}건</div>
          <div>
            <div class="searchTitle">게스트 명 / 코드</div>
            <div class="flex-alignCenter">
              <div style="width: 250px; height: 40px;">
                <v-autocomplete
                  @keyup.enter="search()"
                  auto-select-first
                  append-inner-icon="mdi-magnify"
                  density="compact"
                  variant="outlined"
                  v-model="searchtext"
                  :items="searchList"
                  item-text="title"
                  item-value="value"
                  color="blue"
                  placeholder="학교를 검색해주세요."
                  spellcheck="false"
                  theme="light"
                />
              </div>
            </div>
          </div>
        </div>

      </v-row>
      <v-row>
        <div class="wrap-nav-table" style="width: 1501px">
          <v-window v-model="tab">
            <div>
              <div class="wrap-pagination">
                <v-pagination
                  v-model="current"
                  :length="onLength()"
                  :size="30"
                  :total-visible="10"
                ></v-pagination>
                <button
                  class="GUEST-enroll__visible btn primary"
                  @click="onClickAdd"
                >
                  GUEST 등록
                </button>
              </div>
              <div class="wrapRelative">
                <table class="table_01" style="width: 1500px">
                  <tr>
                    <td class="table-field" style="width: 60px">No</td>
                    <td class="table-field" style="width: 180px">게스트 명</td>
                    <td class="table-field" style="width: 80px">코드</td>
                    <td class="table-field" style="width: 170px">구분</td>
                    <td class="table-field" style="width: 170px">계약시작일</td>
                    <td class="table-field" style="width: 170px">계약만료일</td>
                    <td class="table-field" style="width: 150px">초기비번</td>
                    <td class="table-field">메모</td>
                  </tr>
                  <tr v-for="(j, i) in onPaging(filterSearch)[current - 1]" :key="i">
                    <td class="table-data">{{ Number(i) + 1 }}</td>
                    <td class="table-data">
                      <router-link
                        :to="`/guest/guest-manage/${j.schoolCode}`"
                        @click="onClickLink(j.schoolCode)"
                        >{{ j.schoolName }}</router-link
                      >
                    </td>
                    <td :id="i + 1" class="table-data">{{ j.schoolCode }}</td>
                    <td class="table-data">{{ j.type }}</td>
                    <td class="table-data">
                      {{ dayjs(j.startDate).format("YYYY/MM/DD") }}
                    </td>
                    <td
                      class="table-data"
                      :class="{ expiration: checkDate(j.endDate) }"
                    >
                      {{ dayjs(j.endDate).format("YYYY/MM/DD") }}
                      <span v-if="checkDate(j.endDate)">(만료)</span>
                    </td>
                    <td class="table-data">{{ j.settingPassword }}</td>
                    <td class="table-data table-data-memo">{{ j.memo }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </v-window>
        </div>
      </v-row>
    </div>
  </div>
</template>
  
<script setup>
  import { ref, onBeforeMount, computed, watch } from "vue";
  import { useStore } from "vuex";
  import { useRouter } from "vue-router";

  import dayjs from "dayjs";
  import GuestAddPopup from "../../popup/GuestAddPopupComponent.vue";

  import "../../../assets/style/tableStyle.css";
  import "../../../assets/style/buttonStyle.css";
  import "../../../assets/style/wrapStyle.css";

  let tab = ref(null);
  
  const current = ref(1)

  const schools = ref([]);
  const searchResult = ref([]);
  const filterSearch = ref([])
  const propsSchools = ref([]);
  const searchData = ref({});
  const searchList = ref([]);
  const schoolName = ref("");
  const schoolCode = ref("");
  const searchtext = ref("");
  const selectedSchoolCode = ref("");

  const store = useStore();
  const router = useRouter();

  watch(tab, (nv) => {
    // current.value = 1
    console.log("tab, current : ", nv, current.value)

    current.value = 1
  })

  const onClickAdd = () => {
    store.commit("SET_GUSET_ADD_POPUP_VIEW", true);
  };

  const onClickLink = (code) => {
    sessionStorage.setItem("schoolCode", JSON.stringify(code));
  };

  // 시간 지나면 빨간색으로 되는거 적용
  const checkDate = (date) => {
    const today = dayjs().format("YYYY.MM.DD");
    const getDate = dayjs(date).format("YYYY.MM.DD");

    return dayjs(getDate).isBefore(dayjs(today));
  };

  onBeforeMount(async () => {
    const res = await store.dispatch("GET_SCHOOL_GUEST");
    console.log(res);
    if (res.result === true) {
      schools.value = res.data;
      searchResult.value = res.data;
      filterSearch.value = searchResult.value
      console.log(schools.value);
    }
    propsSchools.value = Object.values(schools.value).map((obj) =>
      obj.schoolName.replace("초등학교", "")
    );
    // console.log(propsSchools.value)

    const { data } = await store.dispatch("GET_SEARCH_GUEST");
    console.log('search data: ', data)
    searchData.value = data;
    searchList.value = Object.getOwnPropertyNames(data);
  });

  const search = async () => {
    let regex = /^[a-zA-Z0-9]*$/;
    const text = searchtext.value;
    let code = "";
    if (regex.test(text)) {
      selectedSchoolCode.value = text;
      code = text;
    } else {
      selectedSchoolCode.value = searchData.value[text];
      code = searchData.value[text];
    }

    console.log(selectedSchoolCode.value);

    let arr = schools.value;
    const result = arr.filter((item) => item.schoolCode == code);
    console.log(result);
    searchResult.value = result;
    filterSearch.value = searchResult.value
    tab.value = 10;
  };

  const goall = () => {
    searchResult.value = schools.value;
    filterSearch.value = searchResult.value
    tab.value = 1;
  };

  const onClickFree = () => {
    const data = searchResult.value.filter(v => v.type !== "교사 연수")
    filterSearch.value = data
  }

  const onClickTeacher = () => {
    const data = searchResult.value.filter(v => v.type === "교사 연수")
    filterSearch.value = data
  }

  const onLength = () => {
    return Math.ceil(filterSearch.value.length / 15)
  }

  const onPaging = (data) => {
    const size = 15
    const arr = []

    for (let i = 0; i < data.length; i += size) {
      arr.push(data.slice(i, i + size))
    }

    return arr;
  }
</script>
  
  <style scoped>
.wrap-pagination {
  position: relative;
  margin: 20px 0 30px 0;
  align-items: center;
}

.tabstyle {
  font-size: 14px;
  font-family: notosanslight;
  font-weight: bold;
  border: 1px solid black;
  color: rgb(190, 190, 190);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-right: 1px solid black;
  /* margin-right: -1px; */
}

.count-search {
  width: fit-content;
  margin: 0 12px 0 0;
}
</style>