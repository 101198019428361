import { createRouter, createWebHistory } from 'vue-router'
import store from '../store/index'
import SeperateView from '../views/SeperateView.vue'
import LoginView from '../components/common/LoginComponent.vue'

// user 계정 관리
import SchoolManage from '../components/userAccountManage/schoolManage/SchoolManageComponent.vue'
import SchoolManageDetail from '../components/userAccountManage/schoolManage/schoolDetail/SchoolDetailComponent.vue'
import AccountIssue from '../components/userAccountManage/accountIssue/UserIdManageComponent.vue'
import AccountSettingSend from '../components/userAccountManage/accountSettingSend/AccountSettingSendView.vue'
import AddTransferStudent from '../components/userAccountManage/addTransferStudent/AddTransferStudentComponent.vue'

// guest 계정 관리
import GuestManage from '../components/guestAccountManage/addModifyGuest/GuestManageView.vue'
import GuestDetail from '../components/guestAccountManage/addModifyGuest/guestSchoolDetail/GuestSchoolDetailComponent.vue'
import FreeApplication from '../components/guestAccountManage/freeExperienceApplication/FreeExperienceView.vue'
import TeacherTrain from '../components/guestAccountManage/teacherTrainAccount/TeacherTrainAccountView.vue'
import IssueGeneralAccount from '../components/guestAccountManage/issueGeneralAccount/IssueGeneralAccountView.vue'

// 견적 발급
import Estimate from '../components/estimate/EstimateComponent.vue'

// 연락처
// import contact from '../components/contact/ContactManage.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: SeperateView
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView
  },

  // user 계정관리
  // 학교 등록 / 수정
  {
    path: '/user/school-manage',
    name: 'school-manage',
    component: SchoolManage,
    meta: {
      auth: true,
      num: 1
    }
  },

  // 학교 등록 / 수정 상세 정보
  {
    path: '/user/school-manage/:code',
    name: 'manage-id',
    component: SchoolManageDetail,
    meta: {
      auth: true,
      num: 1
    }
  },

  // 계정 발급
  {
    path: '/user/account-issue',
    name: 'account-issue',
    component: AccountIssue,
    meta: {
      auth: true,
      num: 2
    }
  },

  // 계정 세팅 / 전달
  {
    path: '/user/account-setting-send',
    name: 'account-setting-send',
    component: AccountSettingSend,
    meta: {
      auth: true,
      num: 3
    }
  },

  // 전학생 추가발급
  {
    path: '/user/add-transfer-student',
    name: 'add-transfer-student',
    component: AddTransferStudent,
    meta: {
      auth: true,
      num: 4
    }
  },

  // guest 계정관리
  // guest 등록 / 수정
  {
    path: '/guest/guest-manage',
    name: 'guest-manage',
    component: GuestManage,
    meta: {
      auth: true,
      num: 5
    }
  },
  // guest 등록 / 수정 상세 정보
  {
    path: '/guest/guest-manage/:schoolCode',
    name: 'guest-manage-id',
    component: GuestDetail,
    props: true,
    meta: {
      auth: true,
      num: 5
    }
  },
  // 무료체험신청 처리
  {
    path: '/guest/free-application',
    name: 'free-application',
    component: FreeApplication,
    meta: {
      auth: true,
      num: 6
    }
  },
  // 교사연수계정 발급
  {
    path: '/guest/teacher-train',
    name: 'teacher-train',
    component: TeacherTrain,
    meta: {
      auth: true,
      num: 7
    }
  },
  // 일반계정 발급
  {
    path: '/guest/issue-general-account',
    name: 'issue-general-account',
    component: IssueGeneralAccount,
    meta: {
      auth: true,
      num: 8
    }
  },

  // 견적발급
  // {
  //   path: '/contact',
  //   name: 'contact',
  //   component: contact,
  //   meta: {
  //     auth: true,
  //     num: 10
  //   }
  // },
  // 연락처
  {
    path: '/estimate',
    name: 'estimate',
    component: Estimate,
    meta: {
      auth: true,
      num: 9
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.auth && !store.getters.logCheck) {
    next('/login')
    return
  }
  next()
})

export default router
