import { createStore } from 'vuex'
import * as actions from './actions.js'
import * as mutations from './mutations.js'

export default createStore({
  state: {
    token: sessionStorage.getItem('token'),
    guestAddPopupView: false,
    messageSendPopupView: false,
    classInfoPopupView: false,
    messageSendListView: false,
    loading: false,
    name: sessionStorage.getItem('name')
  },
  getters: {
    logCheck(state) {
      return state.token !== ''
    }
  },
  mutations,
  actions,
  modules: {}
})
