<template>
    <div class="sub-title">비밀번호 설정</div>
    <div class="flex-alignEnd wrap-input">
        <div class="flex-spaceBetween" style="width: 560px;">
            <div>
                <p class="label-title">학교 선택 (USER) &nbsp;<span>*</span></p>

                <!-- <input v-model="schoolName" @focus="onFocusSearch" type="text" class="input-include-icon" placeholder="학교를 검색해주세요."/>
                
                <div v-if="isResult && schoolName.trim() !== ''" id="recommend" ref="searchRef">
                    <div v-if="re_searchlist.length == 0 && schoolName.trim() !== ''">
                        <div class="nosearchStyle">검색어 없음</div>
                    </div>
                    <div v-else v-for="(j, i) in re_searchlist" :key="i">
                        <div @click="onClickSearch" class="searchStyle" :id="i">{{ j }}</div>
                    </div>
                </div> -->

                <div style="width: 250px; height: 40px;">
                    <v-autocomplete
                    @keyup.enter="schoolsearch()"
                    @change="onChangeTest()"
                    no-data-text="결과를 찾을 수 없습니다."
                    placeholder="학교를 검색해주세요."
                    auto-select-first
                    append-inner-icon="mdi-magnify"
                    item-props
                    density="compact"
                    menu-icon=""
                    variant="outlined"
                    v-model="searchschool"
                    :items="searchlist"
                    color="blue"
                    spellcheck="false"
                    theme="light"
                    ></v-autocomplete>
                </div>

            </div>


            <div>
                <p class="label-title">비밀번호 설정 &nbsp;<span>*</span></p>
                <input
                    v-model="password"
                    type="text"
                    class="input-no-icon"
                    placeholder="6자리 이상, 영어 소문자/숫자 조합"
                />
            </div>
            

        </div>
        <button class="next-to-space-between btn edit" @click="onClickSet">설정</button>
    </div>



    
</template>
<script setup>
    import { ref, onBeforeMount, reactive } from 'vue'
    // import { onClickOutside } from '@vueuse/core'
    import { useStore } from 'vuex'
    import '../../../assets/style/wrapStyle.css'
    import '../../../assets/style/inputStyle.css'
    import '../../../assets/style/buttonStyle.css'
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/searchStyle.css'

    const store = useStore()

    const searchdata = ref("")
    const schoolCode = ref("")
    const searchschool = ref(null)
    const searchlist = ref([])

    const password = ref("")

    const data = reactive({ schoolCode : "", password : "" })

    
    const onClickSet = async() => {
        data.password = password.value.trim()
        const check = /^(?=.*[A-Za-z])(?=.*[0-9])[a-z0-9]{6,}$/.test(data.password)

        if(data.schoolCode !== "" && data.password !== "" && check === true){
            if(confirm("비밀번호를 설정하시겠습니까?")){
        
                const res = await store.dispatch('POST_PASSWORD_CUSTOM', data)
                
                if(res.result === true){
                    alert(`${searchschool.value} 전체 계정 비밀번호가 변경 되었습니다.\n\n변경 비밀번호: ${data.password}`)
                    location.reload(true)
                }
                else if(res === 100){
                    alert("통신에 오류가 발생했습니다.")
                    location.reload(true)
                }
                else {
                    alert("오류가 발생했습니다.")
                    location.reload(true)
                }
            }
        }
        else if(check === false){
            alert('비밀번호가 조건에 충족하지 않습니다.')
        }
        else {
            alert('형식을 모두 작성해주세요.')
        }
        }


    const schoolsearch = async() => {
      if (searchdata.value[searchschool.value]) {
        let regex = /^[a-zA-Z0-9]*$/

        if (regex.test(searchschool.value)) {
          schoolCode.value = searchschool.value
          searchschool.value = searchdata.value[searchschool.value]
          data.schoolCode = schoolCode.value
        //   console.log(data)
        }
        else {
          schoolCode.value = Object.keys(searchdata.value).find(key => searchdata.value[key] === searchschool.value)
          data.schoolCode = schoolCode.value
        //   console.log(data)
        }

        // alert(`학교 이름 : ${searchschool.value}\n학교 코드 : ${schoolcode.value}\n설정되었습니다.`)
      }
      else {
        searchschool.value = "";
        schoolCode.value = "";
        alert("학교 정보가 존재하지 않습니다.");
      }
    }

    const onChangeTest = (newValue) => {
      console.log('change : ', newValue)
    }

    onBeforeMount(async() => {
      try {
        const res = await store.dispatch('GET_SEARCH_LIST')
        // console.log(res)
        searchdata.value = res;
        searchlist.value = Object.getOwnPropertyNames(res);
        // console.log(typeof searchlist.value)
      }
      catch(err) {
        console.log(err)
      }
    })



</script>
<style scoped>

</style>