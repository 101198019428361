<template>
    <div class="wrap-input">

      <div class="sub-title">계정 전달</div>
      <div class="wrap-input">
        <p class="label-title">학교 선택 (USER) &nbsp;<span>*</span></p>

        <div style="width: 250px;">
          <v-autocomplete
            @keyup.enter="schoolsearch()"
            auto-select-first
            append-inner-icon="mdi-magnify"
            item-props
            density="compact"
            menu-icon=""
            variant="outlined"
            v-model="searchschool"
            :items="searchlist"
            color="blue"
            placeholder="학교를 검색해주세요."
            spellcheck="false"
            theme="light"
          ></v-autocomplete>
        </div>
      </div>

    </div>
    <ContactTable :code="schoolCode" :name="searchschool" :contact_info="contactInfo"/>
</template>
<script setup>
    import { ref, onBeforeMount } from 'vue'
    import { useStore } from 'vuex'
    import ContactTable from './ContactTableComponent.vue'
    import '../../../assets/style/inputStyle.css'
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/searchStyle.css'
    import '../../../assets/style/dropdownStyle.css'

    const store = useStore();

    const checkedBox = ref([])
    
    const searchdata = ref("")
    const schoolCode = ref("")
    const schoolName = ref("")
    const searchschool = ref(null)
    const searchlist = ref([])


    // const classinfo= ref([])
    const contactInfo = ref([])
    // const template = ref(null)
    // const checkedContactInfo = ref([])

    // const handleCheckedBox = (check) => {
    //     console.log('check : ', check)
    //     checkedBox.value = check
    // }


    // search bar
    const schoolsearch = async() => {
      if (searchdata.value[searchschool.value]) {
        let regex = /^[a-zA-Z0-9]*$/

        // 학교 코드로 찾았을 때
        if (regex.test(searchschool.value)) {
          console.log('영어!')
          schoolCode.value = searchschool.value
          searchschool.value = searchdata.value[searchschool.value]
          // store.commit('SET_MESSAGE_SEND_LIST_VIEW', false)
        }
        // 학교 이름으로 찾았을 때
        else {
          console.log('한글!')
          schoolCode.value = Object.keys(searchdata.value).find(key => searchdata.value[key] === searchschool.value)
          // store.commit('SET_MESSAGE_SEND_LIST_VIEW', false)
        }
        alert(
          `학교 이름 : ${searchschool.value}\n학교 코드 : ${schoolCode.value}\n설정되었습니다.`
        );

        const contact = await store.dispatch("GET_SCHOOL_CONTACT", schoolCode.value)

        contactInfo.value = contact
        store.commit('SET_MESSAGE_SEND_LIST_VIEW', true)
      }
      else {
        searchschool.value = "";
        schoolCode.value = "";
        alert("학교 정보가 존재하지 않습니다.");
      }
    }

    // const onChangeTest = (newValue) => {
    //   console.log('change : ', newValue)
    // }

    onBeforeMount(async() => {
      try {
        const res = await store.dispatch('GET_SEARCH_LIST')
        // console.log(res)
        searchdata.value = res;
        searchlist.value = Object.getOwnPropertyNames(res);
      }
      catch(err) {
        console.log(err)
      }
    })

    // focus out
    // onMounted(() => {
    //     onClickOutside(searchRef, () => {
    //         isResult.value = false
    //     })
    // })




</script>
<style scoped>
</style>

<!-- {hn: "경동", ho: "성은", hp: "테스", hq: "현리", hr: "오뎅", hy: "냥냥", hz: "멍멍", ib: "디엔", testCode01: "알공", 경동: "hn", 냥냥: "hy", 디엔: "ib", 멍멍: "hz", 성은: "ho", 알공: "testCode01", 오뎅: "hr", 테스: "hp", 현리: "hq"} -->