<template>
  <div>
    <!-- <div class="background" v-show="true"> -->
    <div class="popWapper__scorll">
      <div class="popHeader">
        <div class="popHeader-title">계정 관리</div>
        <button class="pop__invisible" @click="onClickClose"></button>
      </div>

      <div class="popContainer__scroll">
        <table class="table_02" style="width: 500px">
          <tr>
            <td class="table-field">구분</td>
            <td class="table-field">번호</td>
            <td class="table-field">이름</td>
            <td class="table-field">ID</td>
          </tr>
          <tr v-for="(j, i) in classInfo" :key="i">
            <td class="table-data">{{ j.schoolLevel }}</td>
            <td class="table-data">{{ j.attendanceNumber }}</td>
            <td class="table-data">{{ j.name }}</td>
            <td class="table-data">{{ j.id }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import "../../assets/style/popupStyle.css";
import "../../assets/style/titleStyle.css";
import "../../assets/style/wrapStyle.css";
import "../../assets/style/buttonStyle.css";
import "../../assets/style/inputStyle.css";
import "../../assets/style/dropdownStyle.css";

export default {
  props: {
    classInfo: {
      required: true,
    },
  },
  methods: {
    onClickClose() {
      this.$store.state.classInfoPopupView = false;
      this.$emit("closeEvent");
    },
  },
};
</script>


<!-- <script setup>
import { ref, onMounted, computed, defineProps } from "vue";
import { useStore } from "vuex";
import { onClickOutside } from "@vueuse/core";
import "../../assets/style/popupStyle.css";
import "../../assets/style/titleStyle.css";
import "../../assets/style/wrapStyle.css";
import "../../assets/style/buttonStyle.css";
import "../../assets/style/inputStyle.css";
import "../../assets/style/dropdownStyle.css";

const store = useStore();

const info = defineProps(["classInfo"]);
const info2 = info.classInfo;
const info3 = info2.sort((a, b) => a.attendanceNumber - b.attendanceNumber);
const props = info3;

const onClickClose = () => {
  store.commit("SET_CLASS_INFO_POPUP_VIEW", false);
};
</script> -->

<style scoped>
</style>