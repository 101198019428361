<template>
  <div>
    <div style="padding: 50px">
      <p style="font-family: notosans; font-size: 25px">
        USER 계정관리 > 학교등록/수정
      </p>
      <p
        style="
          font-family: notosans;
          font-size: 14px;
          color: rgb(180, 180, 180);
        "
      >
        견적 발급 또는 결제가 확정된 학교의 정보를 등록/수정합니다.
      </p>
      <div style="margin-top: 50px; font-family: notosansregular">
        <div style="float: left; margin-left: 20px">
          <p style="font-family: notosans">[결제단계]</p>
          <div style="margin-top: 15px; zoom: 0.95">
            <circlestate></circlestate>
          </div>
        </div>
        <div style="float: left; margin-left: 600px">
          <p style="font-family: notosans">[계정발급 단계]</p>
          <div style="margin-top: 15px; zoom: 0.95">
            <circlestatesecond></circlestatesecond>
          </div>
        </div>
      </div>
    </div>
    <div style="padding: 50px; margin-top: 40px">
      <v-row>
        <v-tabs
          v-model="tab"
          align-tabs="left"
          color="black"
          style="font-family: notosanslight; font-weight: bold"
        >
          <v-tab @click="goall()" :value="1" class="tabstyle"
            >전체 ({{ statuslist.total }})</v-tab
          >
          <v-tab @click="goestimateissue()" :value="2" class="tabstyle"
            >견적 발급 ({{ statuslist.estimate }})</v-tab
          >
          <v-tab @click="payconfirm()" :value="3" class="tabstyle"
            >결제 확정 ({{ statuslist.payConfirm }})</v-tab
          >
          <v-tab @click="paying()" :value="4" class="tabstyle"
            >결제 진행 ({{ statuslist.payProgress }})</v-tab
          >
          <v-tab @click="paycomplete()" :value="5" class="tabstyle"
            >결제 완료 ({{ statuslist.payComplete }})</v-tab
          >
          <v-tab @click="goneedguide()" :value="9" class="tabstyle"
            >안내 필요 ({{ statuslist.needGuide }})</v-tab
          >
          <v-tab @click="gowaitinginfo()" :value="6" class="tabstyle"
            >정보 대기 ({{ statuslist.waitInfo }})</v-tab
          >
          <v-tab
            @click="needissue()"
            :value="7"
            class="tabstyle"
            style="border-right: 1px solid black"
            >발급 필요 ({{ statuslist.needIssue }})</v-tab
          >
          <v-tab
            @click="issuecomplete()"
            :value="10"
            class="tabstyle"
            style="border-right: 1px solid black"
            >발급 완료 ({{ statuslist.issueComplete }})</v-tab
          >
          <v-tab
            :value="8"
            class="tabstyle"
            style="border-right: 1px solid black"
            >검색 결과</v-tab
          >
        </v-tabs>

        <div style="margin-top: 22px; margin-left: 30px">
          {{ pad(schoolsearchlist.length) }}건
        </div>
        <div
          style="
            margin-top: -13px;
            margin-left: 21px;
            font-family: notosansregular;
          "
        >
          <div
            style="
              font-size: 13px;
              margin-top: -5px;
              font-family: notosansregular;
            "
          >
            학교 / 코드 검색
          </div>
          <div style="width: 225px; margin-top: 5px">
            <v-autocomplete
              @keyup.enter="search(searchtext)"
              auto-select-first
              append-inner-icon="mdi-magnify"
              item-props
              density="compact"
              menu-icon=""
              variant="outlined"
              v-model="searchtext"
              :items="searchlist"
              color="blue"
              placeholder="검색"
              spellcheck="false"
            ></v-autocomplete>
          </div>
        </div>
      </v-row>
      <v-row>
        <div
          style="
            width: 1502px;
            margin-top: -21px;
            border: 1px solid rgb(190, 190, 190);
          "
        >
          <v-window v-model="tab">
            <div>
              <allschool
                :schools="schoolsearchlist"
                v-show="tab != 8"
              ></allschool>
            </div>
            <div>
              <allschool
                :schools="schoolsearchlist"
                v-show="tab == 8"
              ></allschool>
            </div>
          </v-window>
        </div>
      </v-row>
    </div>
  </div>
</template>

<script>
import circlestate from "./Circlestate.vue";
import circlestatesecond from "./Circlestate2.vue";
import allschool from "./allschool.vue";

export default {
  data() {
    return {
      tab: null,
      searchlist: [],
      searchtext: null,
      re_searchlist: [],
      searchresult: "",
      statuslist: [],
      schoollist: [],
      schoolsearchlist: [],
    };
  },
  async created() {
    const name = await this.$store.dispatch("GET_USER_NAME");
    sessionStorage.setItem("name", name);
    this.statuslist = await this.$store.dispatch("SCHOOL_STATUS");
    this.schoollist = await this.$store.dispatch("GET_SCHOOL_LIST");
    for (let i = 0; i < this.schoollist.length; i++) {
      this.schoollist[
        i
      ].schoolName = `${this.schoollist[i].location} ${this.schoollist[i].schoolName}초등학교`;
    }
    this.schoolsearchlist = this.schoollist;
    const res = await this.$store.dispatch("GET_SEARCH_LIST");
    this.searchlist = Object.getOwnPropertyNames(res);
  },
  components: {
    circlestate,
    circlestatesecond,
    allschool,
  },
  watch: {
    searchtext(newtext) {
      this.re_searchlist = this.searchlist.filter((a) => a.includes(newtext));
    },
  },
  methods: {
    picksearch(text) {
      this.searchtext = text;
      this.search(text);
    },
    search(text) {
      let schoollist = this.schoollist;

      schoollist = schoollist.filter(
        (a) => a.schoolName.includes(text) || a.schoolCode.includes(text)
      );

      this.schoolsearchlist = schoollist;

      this.tab = 8;
    },
    schoolsearch() {
      return;
    },
    goestimateissue() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.payStatus == "견적발급");
      this.schoolsearchlist = schoollist;
    },
    payconfirm() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.payStatus == "결제확정");
      this.schoolsearchlist = schoollist;
    },
    paying() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.payStatus == "결제진행");
      this.schoolsearchlist = schoollist;
    },
    paycomplete() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.payStatus == "결제완료");
      this.schoolsearchlist = schoollist;
    },
    gowaitinginfo() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.accountStatus == "정보대기");
      this.schoolsearchlist = schoollist;
    },
    needissue() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.accountStatus == "발급필요");
      this.schoolsearchlist = schoollist;
    },
    goneedguide() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.accountStatus == "안내필요");
      this.schoolsearchlist = schoollist;
    },
    issuecomplete() {
      let schoollist = this.schoollist;
      schoollist = schoollist.filter((a) => a.accountStatus == "발급완료");
      this.schoolsearchlist = schoollist;
    },
    goall() {
      this.schoolsearchlist = this.schoollist;
    },
    pad(num) {
      if (num < 10) {
        return num.toString().padStart(2, "0");
      } else {
        return num;
      }
    },
  },
};
</script>

<style scoped>
.tabstyle {
  font-size: 14px;
  border: 1px solid black;
  color: rgb(190, 190, 190);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* border-right: 1px solid black; */
  border-right: none;
  font-family: notosanslight;
  font-weight: bold;
}

#recommend {
  width: 268px;
  position: absolute;
  background-color: white;
  z-index: 9999;
  overflow-y: scroll;
}
</style>