<template>
  <div>
    <div v-show="!$store.state.token">
      <router-view />
    </div>
    <div v-show="$store.state.token" id="body">
      <v-dialog persistent width="300" v-model="$store.state.loading">
        <div>
          <div class="container">
            <div id="spinner"></div>
          </div>
        </div>
      </v-dialog>
      <div
        style="
          width: 220px;
          height: 100vh;
          background-color: #144572;
          position: fixed;
          top: 0;
          left: 0;
        "
      >
        <div style="width: 100%; height: 100px">
          <v-img
            id="logoimg"
            style="cursor: pointer"
            src="./assets/image/adminlogo.png"
          ></v-img>
        </div>
        <div style="width: 250px; margin-top: 80px">
          <table
            style="
              color: white;
              font-size: 17px;
              font-family: notosansregular;
              width: 100%;
            "
          >
            <!-- <tr>
              <td id="head1" class="tabletd">대시보드</td>
            </tr> -->
            <tr>
              <td id="head2" class="tabletd">USER 계정관리</td>
            </tr>
            <tr>
              <td @click="clickmenu(1)" id="head2sub1" class="subtabletd">
                학교 등록/수정
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(2)" id="head2sub2" class="subtabletd">
                계정 발급
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(3)" id="head2sub3" class="subtabletd">
                계정 세팅 / 전달
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(4)" id="head2sub4" class="subtabletd">
                전학생 추가발급<span v-if="newIssue"
                  ><b style="color: red">&nbsp;*</b></span
                >
              </td>
            </tr>
            <tr>
              <td id="head3" class="tabletd">GUEST 계정관리</td>
            </tr>
            <tr>
              <td @click="clickmenu(5)" id="head3sub1" class="subtabletd">
                GUEST 등록 / 수정
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(6)" id="head3sub2" class="subtabletd">
                무료체험신청 처리
                <span v-if="newFreeApply"><b style="color: red">&nbsp;*</b></span>
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(7)" id="head3sub3" class="subtabletd">
                교사연수계정 발급
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(8)" id="head3sub4" class="subtabletd">
                일반계정 발급
              </td>
            </tr>
            <tr>
              <td @click="clickmenu(9)" id="head4" class="tabletd">
                견적 발급
              </td>
            </tr>
            <!-- <tr>
              <td id="head5" class="tabletd">메일/메세지</td>
            </tr>
            <tr>
              <td @click="clickmenu(10)" id="head5sub1" class="subtabletd">
                연락처
              </td>
            </tr> -->
          </table>
        </div>
      </div>
      <div style="width: 100%">
        <div style="width: 100%; height: 80px; border-bottom: 1px solid black">
          <div style="font-family: notosansregular; font-size: 16px; right: 0">
            <div style="padding: 25px">
              <p
                @click="logout()"
                id="logout"
                style="
                  float: right;
                  cursor: pointer;
                  font-family: notosans;
                  margin-right: 30px;
                "
              >
                로그아웃
              </p>
              <p style="float: right; margin-right: 50px">
                {{ $store.state.name }} 관리자님
              </p>
            </div>
          </div>
        </div>
        <div style="float: left">
          <div
            style="
              position: absolute;
              width: 3000px;
              margin-left: 170px;
              margin-top: -50px;
              padding: 50px;
            "
          >
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import GuestAddPopup from "./components/popup/GuestAddPopupComponent.vue";
import { gettransferstudentlength } from "@/api/user-api-list";
import { getSubscription } from "@/api/guest-api-list";

export default {
  data() {
    return {
      newIssue: false,
      newFreeApply: false,
    };
  },
  setup() {},
  async created() {
    if (this.$store.state.token) {
      const res2 = await getSubscription();
      console.log(res2)
      if(!res2.data.data){
        this.newFreeApply = false;
      }
      else if (res2.data.data.targetEmailList.length > 0) {
        this.newFreeApply = true;
      }
      else this.newFreeApply = false;
    }
  },
  mounted() {
    const num = sessionStorage.getItem("nav");
    this.menucolor(num);
  },
  methods: {
    logout() {
      sessionStorage.removeItem("token");
      location.href = window.location.origin;
    },
    clickmenu(num) {
      sessionStorage.setItem("nav", num);
      // 학교 등록/수정
      if (num == 1) {
        location.href = `${window.location.origin}/user/school-manage`;
      }
      // 계정 발급
      if (num == 2) {
        location.href = `${window.location.origin}/user/account-issue`;
      }
      // 계정 세팅 / 전달
      if (num == 3) {
        location.href = `${window.location.origin}/user/account-setting-send`;
      }
      // 전학생 추가발급
      if (num == 4) {
        location.href = `${window.location.origin}/user/add-transfer-student`;
      }
      // GUEST 등록 / 수정
      if (num == 5) {
        location.href = `${window.location.origin}/guest/guest-manage`;
      }
      // 무료체험신청 처리
      if (num == 6) {
        location.href = `${window.location.origin}/guest/free-application`;
      }
      // 교사연수계정 발급
      if (num == 7) {
        location.href = `${window.location.origin}/guest/teacher-train`;
      }
      if (num == 8) {
        location.href = `${window.location.origin}/guest/issue-general-account`;
      }
      // 견적 발급
      if (num == 9) {
        location.href = `${window.location.origin}/estimate`;
      }
      // 연락처
      if (num == 10) {
        location.href = `${window.location.origin}/contact`;
      }
    },
    menucolor(num) {
      if (num == 1) {
        document.getElementById("head2").style.color = "aquamarine";
        document.getElementById("head2sub1").style.color = "aquamarine";
        document.getElementById("head2sub1").style.textDecoration = "underline";
      }
      if (num == 2) {
        document.getElementById("head2").style.color = "aquamarine";
        document.getElementById("head2sub2").style.color = "aquamarine";
        document.getElementById("head2sub2").style.textDecoration = "underline";
      }
      if (num == 3) {
        document.getElementById("head2").style.color = "aquamarine";
        document.getElementById("head2sub3").style.color = "aquamarine";
        document.getElementById("head2sub3").style.textDecoration = "underline";
      }
      if (num == 4) {
        document.getElementById("head2").style.color = "aquamarine";
        document.getElementById("head2sub4").style.color = "aquamarine";
        document.getElementById("head2sub4").style.textDecoration = "underline";
      }
      if (num == 5) {
        document.getElementById("head3").style.color = "aquamarine";
        document.getElementById("head3sub1").style.color = "aquamarine";
        document.getElementById("head3sub1").style.textDecoration = "underline";
      }
      if (num == 6) {
        document.getElementById("head3").style.color = "aquamarine";
        document.getElementById("head3sub2").style.color = "aquamarine";
        document.getElementById("head3sub2").style.textDecoration = "underline";
      }
      if (num == 7) {
        document.getElementById("head3").style.color = "aquamarine";
        document.getElementById("head3sub3").style.color = "aquamarine";
        document.getElementById("head3sub3").style.textDecoration = "underline";
      }
      if (num == 8) {
        document.getElementById("head3").style.color = "aquamarine";
        document.getElementById("head3sub4").style.color = "aquamarine";
        document.getElementById("head3sub4").style.textDecoration = "underline";
      }
      if (num == 9) {
        document.getElementById("head4").style.color = "aquamarine";
        document.getElementById("head4").style.textDecoration = "underline";
      }
      if (num == 10) {
        document.getElementById("head5").style.color = "aquamarine";
        document.getElementById("head5sub1").style.color = "aquamarine";
        document.getElementById("head5sub1").style.textDecoration = "underline";
      }
    },
  },
  watch: {
    async $route(to, from) {
      sessionStorage.setItem("nav", -1);
      this.menucolor(to.meta.num);

      if (this.$store.state.token) {
        const res = await gettransferstudentlength();
        if (res.data.data) {
          this.newIssue = true;
        }

        const res2 = await getSubscription();
        console.log(res2)
        if(!res2.data.data){
          this.newFreeApply = false;
        }
        else if (res2.data.data.targetEmailList.length > 0) {
          console.log('newFreeApply : ', this.newFreeApply)
          this.newFreeApply = true;
        }
        else{
          console.log('newFreeApply : ', this.newFreeApply)
          this.newFreeApply = false;
        }
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "notosans";
  src: url("./assets/font/NotoSansKR-Bold.otf");
}

@font-face {
  font-family: "notosansmedium";
  src: url("./assets/font/NotoSansKR-Medium.otf");
}

@font-face {
  font-family: "notosanslight";
  src: url("./assets/font/NotoSansKR-Light.otf");
}

@font-face {
  font-family: "notosansregular";
  src: url("./assets/font/NotoSans-Regular.woff2");
}

#logout:hover {
  text-decoration: underline;
}

.container {
  width: 200px;
  height: 200px;
  margin: 20px auto;
  background-color: transparent;
}

#spinner {
  margin: calc(50% - 25px) auto;
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  border: 5px solid rgba(255, 255, 255, 0.3);
  border-top-color: white;
  border-radius: 100%;
  animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.tabletd {
  padding: 35px;
  padding-bottom: 0px;
  font-family: notosans;
  cursor: pointer;
}

.subtabletd {
  padding-left: 45px;
  cursor: pointer;
  font-size: 15px;
  padding-top: 10px;
}

.subtabletd:hover {
  text-decoration: underline;
}

#head4 {
  cursor: pointer;
}

#head4:hover {
  text-decoration: underline;
}
</style>
