<template>
  <div></div>
</template>

<script>
export default {
  created() {
    if (this.$store.state.token) {
      this.$router.replace("/user/school-manage");
    } else {
      this.$router.replace("/login");
    }
  },
};
</script>

<style>
</style>