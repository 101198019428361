<template>
  <div class="wrapper" style="width: 100%">
    <div
      class="content half-background"
      style="width: 505px; height: 450px; background-color: white"
    >
      <div
        style="
          text-align: left;
          width: 350px;
          margin-left: 30px;
          margin-top: 20px;
        "
      >
        <p
          style="
            font-family: notosanslight;
            font-weight: bold;
            font-size: 25px;
            color: black;
          "
        >
          알공 관리자 페이지&nbsp; 🚀
        </p>
        <div
          style="
            width: 350px;
            margin-top: 50px;
            font-family: notosanslight;
            font-weight: bold;
          "
        >
          <v-text-field
            density="compact"
            @keyup.enter="login()"
            variant="outlined"
            v-model="id"
            max-width
            label="ID"
          ></v-text-field>

          <v-text-field
            density="compact"
            @keyup.enter="login()"
            variant="outlined"
            v-model="password"
            type="password"
            max-width
            label="password"
          ></v-text-field>

          <div style="margin-top: 10px">
            <v-btn height="50" variant="outlined" @click="login()" id="loginbtn"
              >로그인</v-btn
            >
          </div>
        </div>
      </div>
      <div
        style="
          width: 100%;
          height: 45px;
          background-color: #91a0f5;
          position: absolute;
          bottom: 0;
          left: 0;
          border-bottom-left-radius: 1rem;
          border-bottom-right-radius: 1rem;
          border-top: 1px solid rgb(180, 180, 180);
        "
      ></div>
    </div>
  </div>
  <!-- <div style="margin-top: 27vh; font-family: notosans">
    <v-row justify="center">
      <p style="font-size: 25px; color: rgb(20, 158, 236)">
        알공 관리자 페이지
      </p>
    </v-row>
    <v-row justify="center">
      <div>
        <br />
        <v-img width="100px" src="../../assets/image/Astronaut.png" />
      </div>
    </v-row>
    <v-row style="margin-top: 30px" justify="center">
      <div>
        <br />
        <v-row style="width: 300px" justify="center">
          <v-text-field
            @keyup.enter="login()"
            variant="outlined"
            v-model="id"
            max-width
            placeholder="ID"
          ></v-text-field>
        </v-row>
        <v-row style="width: 300px" justify="center">
          <v-text-field
            @keyup.enter="login()"
            variant="outlined"
            v-model="password"
            type="password"
            max-width
            placeholder="PW"
          ></v-text-field>
        </v-row>
      </div>
    </v-row>
    <v-row justify="center">
      <div>
        <br />
        <v-btn variant="outlined" @click="login()" id="loginbtn">LOGIN</v-btn>
      </div>
    </v-row>
  </div> -->
</template>


<script>
export default {
  created() {
    if (this.$store.state.token) {
      this.$router.replace({ path: "/user/school-manage" });
    }
  },
  data() {
    return {
      id: "",
      password: "",
    };
  },
  methods: {
    login() {
      this.$store.dispatch("LOGIN", { id: this.id, password: this.password });
    },
  },
};
</script>

<style>
#loginbtn {
  width: 350px;
  height: 50px;
  background-color: #3d5ed4;
  color: white;
  font-size: 18px;
  font-family: notosanslight;
  font-weight: bold;
  border-radius: 8px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100dvh;
}

.content {
  background: coral;
  padding: 3rem;
  border-radius: 1rem;
  border: 1px solid rgb(180, 180, 180);
  position: relative;
}

/* .half-background {
  background-image: linear-gradient(black 50%, gray 50%);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: right;
} */
</style>