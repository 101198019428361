<template>
    <div class="sub-title">방학중지 여부 설정</div>
    <div class="flex-alignEnd wrap-input">
        <div class="flex-spaceBetween" style="width: 560px">
            <div>
                <p class="label-title">학교 선택 (USER) &nbsp;<span>*</span></p>

                <div style="width: 250px; height: 40px;">
                    <v-autocomplete
                    @keyup.enter="schoolsearch()"
                    auto-select-first
                    append-inner-icon="mdi-magnify"
                    item-props
                    density="compact"
                    menu-icon=""
                    variant="outlined"
                    v-model="searchschool"
                    :items="searchlist"
                    color="blue"
                    placeholder="학교를 검색해주세요."
                    spellcheck="false"
                    theme="light"
                    ></v-autocomplete>
                </div>
            </div>

            <div>
                <p class="label-title">방학중지 여부 설정 &nbsp;<span>*</span></p>

                <div class="wrap-dropdown">
                    <div style="position: relative;" @click="toggleDropdown">
                        <input class="input-dropdown" v-model="type" placeholder="방학 중지 여부 선택" readonly/>
                        <div class="down-arrow" :class="{'up-arrow': isOpen}"></div>
                    </div>
                    <div v-if="isOpen" ref="dropboxRef" class="wrap-dropbox-content">
                        <div v-for="(option, index) in options" :key="index" @click="onClickSelectOption(option)">{{ option.title }}</div>
                    </div>
                </div>
            </div>

            
        </div>
        <button class="next-to-space-between btn edit" @click="onClickSet">설정</button>
    </div>
</template>
<script setup>
    import { ref, watch, onMounted, onBeforeMount, reactive } from 'vue'
    import { onClickOutside } from '@vueuse/core'
    import { useStore } from 'vuex'
    import '../../../assets/style/inputStyle.css'
    import '../../../assets/style/buttonStyle.css'
    import '../../../assets/style/titleStyle.css'
    import '../../../assets/style/searchStyle.css'
    import '../../../assets/style/dropdownStyle.css'

    const store = useStore()

    const schoolName = ref("")
    const type = ref(null)

    const isResult = ref(false)
    const isOpen = ref(false)

    const dropboxRef = ref(null)
    

    const searchdata = ref("")
    const schoolCode = ref("")
    const searchschool = ref(null)
    const searchlist = ref([])

    const data = reactive({ schoolCode : "", flag : null })

    const options = ref([{title : "방학 기간 중지", flag: true}, {title : "해당 없음", flag: false}])

    // dropbox
    const toggleDropdown = () => {
        isOpen.value = !isOpen.value;
    }

    const onClickSelectOption = (option) => {
        // console.log(option)
        type.value = option.title;
        data.flag = option.flag
        isOpen.value = false;
    }

    const onClickSet = async() => {
        console.log(data)
        if(data.schoolCode !== "" && data.flag !== null){
            const res = await store.dispatch('POST_STOP_VACATION', data)
            // console.log('component result: ', res, typeof res)
            if(res.result === true){
                if(res.data === true) {
                    alert(`${searchschool.value} 방학중지 여부 설정이 변경 되었습니다.\n\n현재 상태: ${type.value}`)
                    location.reload(true)
                }
                else if (res.data === false){
                    alert("계약이 진행된 적 없는 학교입니다.")
                }
                else {
                    alert("오류가 발생했습니다.")
                    location.reload(true)
                }
            }
            else if(res.result === false) {
                alert("통신에 실패했습니다.")
            }
            else {
                alert("오류가 발생했습니다.")
                location.reload(true)
            }
        }
        else {
            alert('형식을 모두 작성해주세요.')
        }
        
    }

    // search bar
    const schoolsearch = async() => {
      if (searchdata.value[searchschool.value]) {
        let regex = /^[a-zA-Z0-9]*$/

        if (regex.test(searchschool.value)) {
          schoolCode.value = searchschool.value
          searchschool.value = searchdata.value[searchschool.value]
          data.schoolCode = schoolCode.value
        //   console.log(data)
        }
        else {
          schoolCode.value = Object.keys(searchdata.value).find(key => searchdata.value[key] === searchschool.value)
          data.schoolCode = schoolCode.value
        //   console.log(data)
        }

        // alert(`학교 이름 : ${searchschool.value}\n학교 코드 : ${schoolcode.value}\n설정되었습니다.`)
        // console.log(schoolCode.value)

        const res = await store.dispatch("GET_VACATION_STATE", schoolCode.value)
        // console.log(res)

        if(res.result === true) {
            // console.log(options.value.find(option => option.flag === res.data).title)
            type.value = options.value.find(option => option.flag === res.data).title
            data.flag = res.data
            isOpen.value = false;
        }
        else if(res.result === false) {
            alert("계약이 진행된 적 없는 학교입니다.")
        }
        else{
            console.log('에러')
        }
      }
      else {
        searchschool.value = "";
        schoolCode.value = "";
        alert("학교 정보가 존재하지 않습니다.");
      }
    }

    onBeforeMount(async() => {
      try {
        const res = await store.dispatch('GET_SEARCH_LIST')
        // console.log(res)
        searchdata.value = res;
        searchlist.value = Object.getOwnPropertyNames(res);
      }
      catch(err) {
        console.log(err)
      }
    })

    onMounted(() => {
        // onClickOutside(searchRef, () => {
        //     isResult.value = false
        // })
        onClickOutside(dropboxRef, () => {
            isOpen.value = false
        })
    })




</script>
<style scoped>
</style>