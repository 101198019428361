<template>
  <div style="padding: 50px">
    <p style="font-family: notosans; font-size: 25px">
      USER 계정관리 > 계정발급
    </p>
    <p
      style="font-family: notosans; font-size: 14px; color: rgb(180, 180, 180)"
    >
      유료결제 학교의 계정을 발급합니다.
    </p>
    <div style="margin-top: 40px">
      <p style="font-family: notosans">
        학교 정보 업로드
        <b style="color: #f17d29; font-family: notosanslight; font-size: 14px"
          >&nbsp;! 견적 명수, 개월수와 일치하는지 먼저 확인해주세요</b
        >
      </p>
    </div>
    <div style="margin-top: 20px">
      <p style="font-family: notosanslight; font-size: 15px">
        <b>학교 선택</b> &nbsp;<b style="color: red">*</b>
      </p>
      <!-- <input
        v-model="searchschool"
        type="text"
        class="optioninput"
        placeholder="학교를 검색해주세요."
      /> -->
      <div style="width: 550px; font-family: notosanslight; margin-top: 5px">
        <v-autocomplete
          @keyup.enter="schoolsearch()"
          auto-select-first
          append-inner-icon="mdi-magnify"
          item-props
          density="compact"
          menu-icon=""
          variant="outlined"
          v-model="searchschool"
          :items="searchlist"
          color="blue"
          placeholder="학교를 검색해주세요."
          spellcheck="false"
          theme="light"
        ></v-autocomplete>
      </div>
    </div>
    <div style="margin-top: 30px">
      <p style="font-family: notosanslight; font-size: 15px">
        <b>파일 업로드</b> &nbsp;<b style="color: red">*</b>
      </p>
      <!-- <input @change="fileupload()" type="file" accept=".xlsx, .csv" class="optioninputnoicon" /> -->
      <div style="width: 350px; margin-top: 15px; float: left">
        <v-file-input
          density="compact"
          v-model="accountfile"
          variant="outlined"
          label="파일 업로드"
        ></v-file-input>
        <p
          style="
            margin-top: -10px;
            color: red;
            font-family: notosanslight;
            font-weight: bold;
            font-size: 14px;
          "
        >
          {{ fileerrortext }}
        </p>
        <p
          style="
            margin-top: -10px;
            color: green;
            font-family: notosanslight;
            font-weight: bold;
            font-size: 14px;
          "
        >
          {{ successtext }}
        </p>
      </div>
      <div style="margin-top: 17px">
        <v-btn
          @click="filecheck()"
          variant="outlined"
          style="
            background-color: #747474;
            color: white;
            font-family: notosanslight;
            font-weight: bold;
            margin-left: 20px;
          "
          >정보 확인</v-btn
        >
        <v-btn
          @click="postaccountfile()"
          v-if="issueflag == true"
          variant="outlined"
          style="
            background-color: #0e7bcb;
            color: white;
            font-family: notosanslight;
            font-weight: bold;
            margin-left: 20px;
          "
          >계정 생성</v-btn
        >
      </div>
    </div>
    <div style="margin-top: 80px">
      <div
        v-if="isSent == true"
        style="
          width: 900px;
          font-family: notosansregular;
          background-color: #d7f0fb;
        "
      >
        <div style="padding: 30px; font-size: 15px">
          <p>계정이 정상적으로 발급 완료 되었습니다.</p>
          <p>
            추가 계정 세팅 및 계정 전달을 원하시면
            <b>‘계정 세팅/전달’ 페이지로 이동</b>해주세요.
          </p>
        </div>
      </div>
      <div v-if="isChecked == true" style="margin-top: 60px">
        <p style="font-family: notosans">학교 정보</p>
        <table style="border-spacing: 0; margin-top: 10px">
          <tr>
            <td class="tabletd">학교 이름</td>
            <td class="tabletd">코드</td>
            <td class="tabletd">계약 시작일</td>
            <td class="tabletd">계약 만료일</td>
            <td class="tabletd">학생 계정수</td>
            <td class="tabletd">교사 계정수</td>
            <td class="tabletd tabletd2last">LMS 계정수</td>
          </tr>
          <tr>
            <td class="tabletd2">{{ accountschoolinfo.schoolname }}</td>
            <td class="tabletd2">{{ accountschoolinfo.schoolcode }}</td>
            <td class="tabletd2">{{ accountschoolinfo.startDay }}</td>
            <td class="tabletd2">{{ accountschoolinfo.endDay }}</td>
            <td class="tabletd2" style="background-color: #e4e4e4">
              {{ accountinfo.studentList.length }}
            </td>
            <td class="tabletd2" style="background-color: #e4e4e4">
              {{ accountinfo.teacherList.length }}
            </td>
            <td class="tabletd2 tabletd2last" style="background-color: #e4e4e4">
              {{ accountinfo.lmsList.length }}
            </td>
          </tr>
        </table>
      </div>
      <div v-if="isChecked == true" style="margin-top: 50px">
        <div>
          <p style="font-family: notosans">학급 정보</p>
          <table style="border-spacing: 0; margin-top: 10px">
            <tr>
              <td class="tabletd">학년</td>
              <td class="tabletd">반</td>
              <td class="tabletd">출판사</td>
              <td class="tabletd">학생수</td>
              <td class="tabletd">영어 교사</td>
              <td class="tabletd tabletd2last">담임 교사</td>
            </tr>
            <tr v-for="(j, i) in accountclassinfo" :key="i">
              <td class="tabletd2">{{ j.grade || "-" }}</td>
              <td class="tabletd2">{{ j.classNum || "-" }}</td>
              <td class="tabletd2">{{ j.publisher || "-" }}</td>
              <td class="tabletd2">{{ j.studentCnt || "-" }}</td>
              <td class="tabletd2">{{ j.engTeacher || "-" }}</td>
              <td class="tabletd2 tabletd2last">{{ j.teacher || "-" }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 30px">
          <p style="font-family: notosans">교사 LMS 계정</p>
          <table style="border-spacing: 0; margin-top: 10px">
            <tr>
              <td class="tabletd">이름</td>
              <td class="tabletd">ID</td>
              <td class="tabletd tabletd2last">담당 학급</td>
            </tr>
            <tr v-for="(j, i) in accountinfo.lmsList" :key="i">
              <td class="tabletd2">{{ j.name }}</td>
              <td class="tabletd2">{{ j.id }}</td>
              <td class="tabletd2 tabletd2last">
                {{ j.classList.join(", ") }}
              </td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 30px">
          <p style="font-family: notosans">교사 알공 계정</p>
          <table style="border-spacing: 0; margin-top: 10px">
            <tr>
              <td class="tabletd">학년</td>
              <td class="tabletd">반</td>
              <td class="tabletd">이름</td>
              <td class="tabletd tabletd2last">아이디</td>
            </tr>
            <tr v-for="(j, i) in accountinfo.teacherList" :key="i">
              <td class="tabletd2">{{ j.grade }}</td>
              <td class="tabletd2">{{ j.classNum }}</td>
              <td class="tabletd2">{{ j.name }}</td>
              <td class="tabletd2 tabletd2last">{{ j.id }}</td>
            </tr>
          </table>
        </div>
        <div style="margin-top: 30px">
          <p style="font-family: notosans">학생 알공 계정</p>
          <table style="border-spacing: 0; margin-top: 10px">
            <tr>
              <td class="tabletd">학년</td>
              <td class="tabletd">반</td>
              <td class="tabletd">이름</td>
              <td class="tabletd tabletd2last">아이디</td>
            </tr>
            <tr v-for="(j, i) in accountinfo.studentList" :key="i">
              <td class="tabletd2">{{ j.grade }}</td>
              <td class="tabletd2">{{ j.classNum }}</td>
              <td class="tabletd2">{{ j.name }}</td>
              <td class="tabletd2 tabletd2last">{{ j.id }}</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getaccountissuerecord,
  setaccountissuerecord,
} from "@/api/user-api-list";

export default {
  async created() {
    const res = await this.$store.dispatch("GET_SEARCH_LIST");
    this.searchdata = res;
    this.searchlist = Object.getOwnPropertyNames(res);
  },
  data() {
    return {
      searchdata: "",
      searchlist: [],
      searchschool: null,
      fileerrortext: "",
      successtext: "",
      schoolcode: "",
      isChecked: false,
      isSent: false,
      accountfile: [],
      issueflag: false,
      accountclassinfo: [],
      accountschoolinfo: {},
      accountinfo: {},
    };
  },
  methods: {
    async schoolsearch() {
      if (this.searchdata[this.searchschool]) {
        let regex = /^[a-zA-Z0-9]*$/;

        if (regex.test(this.searchschool)) {
          this.schoolcode = this.searchschool;
          this.searchschool = this.searchdata[this.searchschool];
        } else {
          this.schoolcode = this.searchdata[this.searchschool];
        }

        const res = await getaccountissuerecord({
          schoolCode: this.schoolcode,
        });

        if (res.data.data.length) {
          alert("해당 학교에 발급 후 전달되지 않은 계정이 존재합니다.");
          return;
        }

        alert(
          `학교 이름 : ${this.searchschool}\n학교 코드 : ${this.schoolcode}\n설정되었습니다.`
        );

        // const res = await this.$store.dispatch(
        //   "GET_TYPE_USERS",
        //   this.schoolcode
        // );

        // const res2 = await getaccountissuerecord({
        //   schoolCode: this.schoolcode,
        // });

        // console.log(res2);
      } else {
        this.searchschool = "";
        this.schoolcode = "";
        alert("학교 정보가 존재하지 않습니다.");
      }
    },
    paging(data) {
      const size = 5;
      const arr = [];

      for (let i = 0; i < data.length; i += size) {
        arr.push(data.slice(i, i + size));
      }

      return arr;
    },
    async filecheck() {
      if (!this.schoolcode) {
        this.fileerrortext = "먼저 학교를 검색해주세요.";
        return;
      }

      if (!this.accountfile[0]) {
        this.fileerrortext = "먼저 파일을 업로드해주세요.";
        return;
      }

      const req = this.accountfile[0];

      let dot_index = req.name.lastIndexOf(".");
      let temp = req.name.substring(dot_index + 1, req.name.length);
      temp = temp.toLowerCase();

      if (temp !== "xlsx" && temp !== "csv") {
        this.fileerrortext = "엑셀 파일만 업로드해주세요";
        return;
      }

      const data = new FormData();
      data.append("file", req);

      const res = await this.$store.dispatch("ACCOUNT_FILE_CHECK", data);

      if (res == true) {
        this.issueflag = true;
        this.fileerrortext = "";
        this.successtext = "유효성 검사를 통과하였습니다.";
        return;
      } else {
        this.issueflag = false;
        this.successtext = "";
        this.fileerrortext = res;
        this.accountfile = [];
        return;
      }
    },
    async postaccountfile() {
      this.$store.state.loading = true;

      const formdata = new FormData();
      formdata.append("file", this.accountfile[0]);
      formdata.append("schoolCode", this.schoolcode);

      const res = await this.$store.dispatch("POST_ACCOUNT_FILE", formdata);

      if (res.schoolStartDate) {
        setTimeout(async () => {
          this.accountclassinfo = await this.$store.dispatch(
            "GET_SCHOOL_CLASS_LIST",
            this.schoolcode
          );

          const startDay = res.schoolStartDate.split("T")[0];
          const endDay = res.schoolEndDate.split("T")[0];

          this.accountschoolinfo = {
            schoolname: this.searchschool,
            schoolcode: this.schoolcode,
            startDay,
            endDay,
          };

          this.accountinfo = await this.$store.dispatch(
            "GET_TYPE_USERS",
            this.schoolcode
          );

          await setaccountissuerecord({
            schoolCode: this.schoolcode,
            accounts: this.accountinfo.lmsList,
          });

          this.$store.state.loading = false;

          this.isSent = true;
          this.isChecked = true;
          alert("계정이 생성되었습니다.");
        }, 2000);
        return;
      } else {
        alert("계정 생성 중 오류가 발생하였습니다.");
        return;
      }
    },
  },
};
</script>

<style scoped>
.optioninput {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 250px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  border-radius: 7px;
  background-image: url("../../../assets/image/searchicon.png");
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 221px;
}

.optioninputnoicon {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 350px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  border-radius: 7px;
}

.tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 5px;
  font-size: 13px;
  width: 150px;
  text-align: center;
  background-color: rgb(250, 250, 250);
  border-right: none;
  border-bottom: none;
}

.tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  border-right: none;
}

.tabletd2last {
  border-right: 1px solid #7f7f7f;
}
</style>