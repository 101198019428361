<template>
  <div style="padding: 50px">
    <p style="font-family: notosans; font-size: 25px">견적 발급</p>
    <p
      style="font-family: notosans; font-size: 14px; color: rgb(180, 180, 180)"
    >
      견적에 필요한 정보 입력 후, 메일을 통해 견적서 PDF 파일을 전송할 수
      있습니다.
    </p>
    <div style="margin-top: 10px">
      <p style="font-family: notosans; font-size: 17px; margin-top: 30px">
        학교 검색하기
      </p>
      <div>
        <p
          style="font-family: notosanslight; font-size: 15px; margin-top: 20px"
        >
          <b>학교 선택</b> &nbsp;<b style="color: red">*</b>
        </p>
        <!-- <input
          @keyup="schoolsearch()"
          spellcheck="false"
          v-model="searchschool"
          type="text"
          class="optioninputlong"
          placeholder="학교를 검색해주세요."
        /> -->
        <div
          style="
            width: 550px;
            margin-top: 8px;
            font-family: notosanslight;
            font-weight: bold;
          "
        >
          <div style="float: left; width: 450px">
            <v-autocomplete
              @keyup.enter="schoolsearch()"
              auto-select-first
              append-inner-icon="mdi-magnify"
              item-props
              density="compact"
              menu-icon=""
              variant="outlined"
              v-model="searchschool"
              :items="searchlist"
              color="blue"
              placeholder="학교를 검색해주세요."
              spellcheck="false"
              theme="light"
            ></v-autocomplete>
          </div>
          <div style="float: left; margin-left: 20px">
            <v-btn
              @click="schoolsearch()"
              style="
                background-color: #0e7bcb;
                color: white;
                font-family: notosanslight;
                font-weight: bold;
                margin-top: 2px;
              "
              variant="outlined"
              >검색</v-btn
            >
          </div>
        </div>
        <p
          style="
            font-family: notosanslight;
            color: red;
            font-size: 14px;
            font-weight: bold;
            margin-top: -20px;
            float: left;
          "
        >
          {{ schoolerror }}
        </p>
      </div>
    </div>
    <div>
      <p style="font-family: notosans; font-size: 17px; margin-top: 100px">
        견적 내용 작성
      </p>
      <div style="margin-top: 20px">
        <div style="float: left">
          <p style="font-family: notosanslight; font-size: 15px">
            <b>계약 개월수</b> &nbsp;<b style="color: red">*</b>
          </p>
          <input
            v-model="contractmonth"
            type="text"
            class="optioninputnoicon"
            placeholder="숫자를 입력해주세요."
          />
          <p
            style="
              font-family: notosanslight;
              color: red;
              font-size: 14px;
              font-weight: bold;
              margin-top: 3px;
            "
          >
            {{ montherror }}
          </p>
        </div>
        <div style="float: left; margin-left: 50px">
          <p style="font-family: notosanslight; font-size: 15px">
            <b>계약 학생수</b> &nbsp;<b style="color: red">*</b>
          </p>
          <input
            v-model="contractamount"
            type="text"
            class="optioninputnoicon"
            placeholder="숫자를 입력해주세요."
          />
          <p
            style="
              font-family: notosanslight;
              color: red;
              font-size: 14px;
              font-weight: bold;
              margin-top: 3px;
            "
          >
            {{ amounterror }}
          </p>
        </div>
        <div style="float: left; margin-left: 50px">
          <div>
            <p class="inputTitle" style="margin-top: -1px">
              요금 플랜 &nbsp;<span style="color: red">*</span>
            </p>
            <div style="width: 300px">
              <select
                required
                v-model="plan"
                name="plan"
                class="planselect"
                placeholder="요금 플랜을 선택해주세요"
              >
                <option value="" disabled selected>
                  요금 플랜을 선택해주세요
                </option>
                <option value="general" style="font-size: 16px">
                  일반 플랜
                </option>
                <option value="premium" style="font-size: 16px">
                  프리미엄 플랜
                </option>
              </select>
            </div>
          </div>
          <p
            style="
              font-family: notosanslight;
              color: red;
              font-size: 14px;
              font-weight: bold;
              margin-top: 3px;
            "
          >
            {{ planerror }}
          </p>
        </div>
        <v-btn
          @click="caculatemoney()"
          variant="outlined"
          style="
            margin-top: 30px;
            margin-left: 10px;
            background-color: #747474;
            font-family: notosanslight;
            color: white;
          "
          >확인</v-btn
        >
      </div>
      <div style="margin-top: 50px">
        <p style="font-family: notosans; font-size: 17px">견적 확인</p>
      </div>
      <div style="margin-top: 20px; float: left">
        <p style="font-family: notosanslight; font-size: 15px">
          <b>견적 결과</b>
        </p>
        <div>
          <div>
            <input
              v-model="totalmoney"
              disabled
              type="text"
              class="fixmoney"
              placeholder="금액"
            />
            <div class="moneysentence">
              <p>
                {{ contractmonth }}개월 X {{ contractamount }}명 X
                {{ money_per }}원 = {{ showmoney }}원
              </p>
              <p>{{ real_money_per }}원 단위 절삭 = {{ realmoney }}원</p>
            </div>
          </div>
        </div>
        <div style="margin-top: 70px">
          <p style="font-family: notosanslight; font-size: 15px">
            <b>견적 최종액 입력</b> &nbsp;<b style="color: red">*</b>
          </p>
          <div style="float: left">
            <input
              v-show="estimateflag == false"
              v-model="realmoney"
              type="text"
              class="optioninputnoicon"
              style="width: 300px"
              placeholder="숫자를 입력해주세요."
            />
            <input
              disabled
              v-show="estimateflag == true"
              v-model="realmoney"
              type="text"
              class="optioninputnoicon"
              style="width: 300px; background-color: rgb(240, 240, 240)"
              placeholder="숫자를 입력해주세요."
            />
            <p
              style="
                font-family: notosanslight;
                color: red;
                font-size: 14px;
                font-weight: bold;
                margin-top: 3px;
              "
            >
              {{ lastmoneyerror }}
            </p>
          </div>
          <v-btn
            v-show="estimateflag == false"
            @click="goestimate()"
            variant="outlined"
            style="
              margin-top: 7px;
              margin-left: 30px;
              background-color: #747474;
              font-family: notosanslight;
              color: white;
            "
            >발급하기</v-btn
          >
          <v-btn
            disabled
            v-show="estimateflag == true"
            variant="outlined"
            style="
              margin-top: 7px;
              margin-left: 30px;
              background-color: gray;
              font-family: notosansregular;
              color: white;
            "
            >발급하기</v-btn
          >
          <v-btn
            @click="estimateinit()"
            v-show="estimateflag == true"
            variant="outlined"
            style="
              margin-top: 7px;
              margin-left: 30px;
              background-color: #0e7bcb;
              font-family: notosansregular;
              color: white;
            "
            >견적 초기화</v-btn
          >
          <!-- <v-btn
            @click="downloadestimate()"
            v-show="estimateflag == true"
            variant="outlined"
            style="
              margin-top: 7px;
              margin-left: 30px;
              background-color: green;
              font-family: notosansregular;
              color: white;
            "
            >견적 다운로드</v-btn
          > -->
        </div>

        <div style="padding-bottom: 100px">
          <div style="margin-top: 70px">
            <p style="font-family: notosans; font-size: 18px">견적 보내기</p>
          </div>
          <div style="padding-bottom: 60px">
            <div style="margin-top: 15px">
              <p style="font-family: notosans">견적 및 연락처 정보 확인</p>
            </div>
            <div style="margin-top: 10px">
              <table
                style="width: 1000px; border-spacing: 0; text-align: center"
              >
                <tr>
                  <td class="tabletd">no.</td>
                  <td class="tabletd">요금플랜</td>
                  <td class="tabletd">개월수</td>
                  <td class="tabletd">학생수</td>
                  <td class="tabletd">추가 할인액</td>
                  <td class="tabletd">견적 최종가</td>
                  <td class="tabletd">견적일시</td>
                  <td class="tabletd">견적 담당자 ID</td>
                  <td class="tabletd">선택</td>
                  <td class="tabletd">다운로드</td>
                </tr>
                <tr v-for="(j, i) in estimatelist" :key="i">
                  <td class="tabletd2">{{ i + 1 }}</td>
                  <td class="tabletd2">{{ getplanstring(j.plan) }}</td>
                  <td class="tabletd2">{{ j.period }}</td>
                  <td class="tabletd2">{{ j.quantity }}</td>
                  <td class="tabletd2">{{ getlocalestring(j.discount) }}</td>
                  <td class="tabletd2">
                    {{ getlocalestring(j.price - j.discount) }}
                  </td>
                  <td class="tabletd2">{{ j.time }}</td>
                  <td class="tabletd2">{{ j.manager }}</td>
                  <td class="tabletd2">
                    <input v-model="j.isSelected" type="checkbox" />
                  </td>
                  <td class="tabletd2">
                    <v-btn
                      @click="downloadestimate(j.idx)"
                      variant="outlined"
                      style="background-color: green; color: white"
                      >다운로드</v-btn
                    >
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <!-- <div style="margin-top: 10px">
          <p style="font-family: notosanslight; font-size: 15px">
            <b>학교 검색</b>
          </p>
          <input
            type="text"
            class="optioninput"
            placeholder="학교를 검색해주세요."
          />
        </div> -->
          <div style="margin-top: -20px">
            <table style="width: 1000px; border-spacing: 0; text-align: center">
              <tr>
                <td class="tabletd">이름</td>
                <td class="tabletd">이메일주소</td>
                <td class="tabletd">전화번호</td>
                <td class="tabletd">메모</td>
                <td class="tabletd">메일 (필수)</td>
                <td class="tabletd">문자 (필수)</td>
              </tr>
              <tr v-for="(j, i) in contactlist" :key="i">
                <td class="tabletd2">
                  {{ j.name }}
                </td>
                <td class="tabletd2">
                  {{ j.mail }}
                </td>
                <td class="tabletd2">
                  {{ j.tel }}
                </td>
                <td class="tabletd2">{{ j.memo || "-" }}</td>
                <td class="tabletd2">
                  <input v-model="j.mailCheck" type="checkbox" />
                </td>
                <td class="tabletd2">
                  <input v-model="j.telCheck" type="checkbox" />
                </td>
              </tr>
            </table>
          </div>
          <div style="width: 1000px; margin-top: 30px">
            <v-btn
              @click="sendmail()"
              style="
                float: right;
                margin-top: -10px;
                color: white;
                background-color: #0e7bcb;
                font-family: notosans;
              "
              variant="outlined"
              >발송하기</v-btn
            >
          </div>
        </div>
      </div>
      <div id="estimatedoc" style="float: left; margin-top: -200px">
        <div
          @click="estimate_full()"
          v-html="sample_html"
          style="cursor: pointer; width: 700px; zoom: 0.35"
        ></div>
      </div>
    </div>
    <v-dialog v-model="fullmodal" width="1000" height="1000">
      <v-card width="1000" height="1100">
        <div v-html="sample_html" style="zoom: 0.85"></div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alimmodal" width="400" height="300">
      <v-card width="400" height="300">
        <div
          style="
            position: absolute;
            width: 100%;
            height: 60px;
            top: 0;
            background-color: #0e7bcb;
          "
        >
          <p
            style="
              color: white;
              font-size: 20px;
              font-family: notosansregular;
              margin-top: 13px;
              margin-left: 20px;
            "
          >
            알림
          </p>
        </div>
        <div style="margin-top: 100px; width: 370px">
          <p
            style="
              margin-left: 40px;
              font-family: notosansregular;
              font-size: 17px;
            "
          >
            {{ real_money_per }}원 단위 구매가 불가한 금액입니다. ‘학교장터’가
            아닌 ‘계좌이체’ 진행 학교가 맞는지 확인해주세요. 이대로 견적을
            발급하려면 확인 버튼을 눌러주세요.
          </p>
        </div>
        <div
          style="
            position: absolute;
            right: 0;
            bottom: 0;
            margin-bottom: 20px;
            margin-right: 20px;
            font-family: notosansregular;
          "
        >
          <v-btn
            @click="reallycancel()"
            width="80"
            height="45"
            variant="outlined"
            style="font-size: 17px"
            >취소</v-btn
          >
          <v-btn
            @click="reallycheck()"
            width="80"
            height="45"
            variant="outlined"
            style="
              margin-left: 10px;
              background-color: #747474;
              color: white;
              font-size: 17px;
            "
            >확인</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="alimalimmodal" width="400" height="250">
      <v-card width="400" height="250">
        <div
          style="
            position: absolute;
            width: 100%;
            height: 60px;
            top: 0;
            background-color: #0e7bcb;
          "
        >
          <p
            style="
              color: white;
              font-size: 20px;
              font-family: notosansregular;
              margin-top: 13px;
              margin-left: 20px;
            "
          >
            알림
          </p>
        </div>
        <div style="margin-top: 100px; width: 370px">
          <p
            style="
              margin-left: 40px;
              font-family: notosansregular;
              font-size: 17px;
            "
          >
            견적 결과와 최종액이 다릅니다. <br />이대로 발급 하시겠습니까?
          </p>
        </div>
        <div
          style="
            position: absolute;
            right: 0;
            bottom: 0;
            margin-bottom: 20px;
            margin-right: 20px;
            font-family: notosansregular;
          "
        >
          <v-btn
            @click="reallyreallycancel()"
            width="80"
            height="45"
            variant="outlined"
            style="font-size: 17px"
            >취소</v-btn
          >
          <v-btn
            @click="reallyreallycheck()"
            width="80"
            height="45"
            variant="outlined"
            style="
              margin-left: 10px;
              background-color: #747474;
              color: white;
              font-size: 17px;
            "
            >확인</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  changeschoolpaystatus,
  changeschoolaccountstatus,
} from "@/api/user-api-list";

export default {
  data() {
    return {
      searchschool: null,
      schoolname: "",
      contractmonth: "",
      contractamount: "",
      fullmoney: "",
      totalmoney: "",
      realmoney: "",
      real_money_per: "",
      money_per: "",
      estimate_date: "",
      expire_date: "",
      fullmodal: false,
      alimmodal: false,
      alimalimmodal: false,
      estimateflag: false,
      schoolerror: "",
      montherror: "",
      amounterror: "",
      lastmoneyerror: "",
      realrealmoney: "",
      onlyaccount: false,
      fullmodaltwo: false,
      showmoney: "",
      moneyexpire: "",
      plan: "",
      planerror: "",
      money_per_discount: 0,
      discounttext: "",
      money_per_num: "",
      estimate_url: "",
      estimate_idx: "",
      sample_html: "",
      schoolcode: "",
      contactlist: [],
      count: {
        mail: {
          good: [],
          bad: [],
        },
        sms: {
          good: [],
          bad: [],
        },
      },
      isestimate: false,
      searchdata: "",
      searchlist: [],
      searchcandidate: "",
      estimatelist: [],
    };
  },
  async created() {
    const res = await this.$store.dispatch("GET_SEARCH_LIST");
    this.searchdata = res;
    this.searchlist = Object.getOwnPropertyNames(res);
  },
  methods: {
    async estimateRegister() {
      const price =
        this.contractmonth * this.contractamount * this.real_money_per;
      const discount =
        this.contractmonth * this.contractamount * this.money_per_num;

      let realmoney_num = this.realmoney.replace(/,/g, "");

      const real_money = parseInt(realmoney_num);
      const final_money = discount;
      const remain = this.getremain(real_money, this.real_money_per);

      const data = {
        teachername: "담당",
        schoolName: this.schoolname.trim(),
        schoolCode: this.schoolcode,
        period: parseInt(this.contractmonth),
        quantity: parseInt(this.contractamount),
        price,
        discount: price - discount,
        plan: this.plan,
        money_per_discount: this.money_per_discount,
        discount_text: this.discounttext,
        final_money,
        real_money,
        remain,
        money_expire: this.moneyexpire,
        money_per: this.real_money_per,
        manager: sessionStorage.getItem("id"),
      };

      const res = await this.$store.dispatch("ESTIMATE_REGISTER", data);

      const res2 = await this.$store.dispatch("GET_ESTIMATE_SAMPLE", {
        type: this.plan,
        remain,
        data,
      });

      this.sample_html = res2;

      this.estimate_url = `https://kr.object.gov-ncloudstorage.com/dn-bucket/estimate/${this.schoolcode}/${res}.pdf`;
      this.estimate_idx = res;

      const date = new Date();

      this.estimatelist.push({
        idx: res,
        plan: this.plan,
        period: parseInt(this.contractmonth),
        quantity: parseInt(this.contractamount),
        price,
        discount: price - discount,
        manager: sessionStorage.getItem("id"),
        isSelected: false,
        time: `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${date
          .getDate()
          .toString()
          .padStart(2, "0")} ${date
          .getHours()
          .toString()
          .padStart(2, "0")}:${date
          .getMinutes()
          .toString()
          .padStart(2, "0")}:${date.getSeconds().toString().padStart(2, "0")}`,
      });

      return;
    },
    getremain(a, b) {
      if (a % b == 0) {
        return 1;
      } else {
        return 0;
      }
    },
    estimateinit() {
      this.totalmoney = "";
      this.realmoney = "";
      this.contractamount = "";
      this.contractmonth = "";
      this.sample_html = "";
      this.estimateflag = false;
      this.onlyaccount = false;
    },
    caculatemoney() {
      const amount = this.contractamount;
      const month = this.contractmonth;

      if (
        this.schoolname == "" ||
        this.searchschool == "" ||
        this.schoolcode == ""
      ) {
        this.schoolerror = "학교를 입력해주세요.";
      } else {
        this.schoolerror = "";
      }

      if (isNaN(month) || month == "") {
        this.montherror = "숫자만 입력해주세요.";
      } else {
        this.montherror = "";
      }

      if (!this.plan) {
        this.planerror = "요금 플랜을 선택해주세요.";
      }

      if (isNaN(amount) || month == "") {
        this.amounterror = "숫자만 입력해주세요.";
        return;
      }

      this.schoolname = this.searchschool;
      this.schoolerror = "";
      this.amounterror = "";

      let money = 0;
      let money_ori = 0;

      if (this.plan == "premium") {
        this.real_money_per = 9000;
        money_ori = 9000;
        money = 9000;
      }

      if (this.plan == "general") {
        this.real_money_per = 6000;
        money_ori = 6000;
        money = 6000;
      }

      if (amount > 0 && amount < 100) {
        this.discounttext = "-";
        this.money_per_discount = 0;
      }

      if (amount >= 100 && amount < 400) {
        this.money_per_discount = money * 0.1;

        money = money * 0.9;
        this.discounttext = "100명 이상 결제 시, 10% 할인 적용";
      }

      if (amount >= 400 && amount < 700) {
        this.money_per_discount = money * 0.15;

        money = money * 0.85;
        this.discounttext = "400명 이상 결제 시, 15% 할인 적용";
      }

      if (amount >= 700) {
        this.money_per_discount = money * 0.2;

        money = money * 0.8;
        this.discounttext = "700명 이상 결제 시, 20% 할인 적용";
      }

      this.money_per_num = money;
      this.money_per = money.toLocaleString();

      const full = money * month * amount;
      const remain = full % money_ori;

      this.showmoney = full.toLocaleString();

      this.totalmoney = (full - remain).toLocaleString();
      this.realmoney = (full - remain).toLocaleString();

      let now = dayjs();

      this.estimate_date = dayjs(now, "YYYY-MM-DD").format().split("T")[0];

      this.expire_date = dayjs(
        now.add(this.contractmonth, "month"),
        "YYYY-MM-DD"
      )
        .format()
        .split("T")[0];

      this.moneyexpire = dayjs(now.add(1, "month"), "YYYY-MM-DD")
        .format()
        .split("T")[0];

      //this.searchSchoolCode(this.schoolname);
    },
    estimate_full() {
      this.fullmodal = true;
    },
    goestimate() {
      if (!this.schoolname || !this.contractmonth || !this.contractamount) {
        this.lastmoneyerror = "견적 내용을 먼저 작성해주세요.";
        return;
      }

      if (isNaN(this.renum(this.realmoney))) {
        this.lastmoneyerror = "숫자만 입력해주세요.";
        return;
      }

      if (this.renum(this.realmoney) % this.real_money_per != 0) {
        this.alimmodal = true;
        return;
      } else {
        if (this.totalmoney != this.realmoney) {
          this.alimalimmodal = true;
          return;
        } else {
          this.estimateflag = true;

          this.realmoney = this.realmoney.toLocaleString();
        }
      }

      this.estimateRegister();
    },
    downloadestimate(idx) {
      setTimeout(() => {
        window.open(
          `https://argongadmin.argong.io/estimate/download?url=https://kr.object.gov-ncloudstorage.com/dn-bucket/estimate/${this.schoolcode}/${idx}.pdf&schoolName=${this.searchschool}`
        );
      }, 1000);
    },
    renum() {
      return this.realmoney.replace(/\D/g, "");
    },
    really() {
      this.alimmodal = true;
    },
    reallycheck() {
      if (this.totalmoney != this.realmoney) {
        this.alimmodal = false;
        this.onlyaccount = true;
        this.reallyreally();
      } else {
        this.alimmodal = false;

        this.estimateflag = true;
        this.onlyaccount = true;
        this.realmoney = this.realmoney.toLocaleString();
        this.estimateRegister();
      }
    },
    reallycancel() {
      this.alimmodal = false;
    },
    reallyreally() {
      this.alimalimmodal = true;
    },
    reallyreallycheck() {
      this.alimalimmodal = false;
      this.estimateflag = true;
      this.realmoney = this.realmoney.toLocaleString();
      this.estimateRegister();
    },
    reallyreallycancel() {
      this.alimalimmodal = false;
    },
    async searchSchoolCode(schoolname) {
      this.schoolcode = "testCode01";
      this.contactlist = await this.$store.dispatch(
        "GET_SCHOOL_CONTACT",
        this.schoolcode
      );
      return;
    },
    async sendmail() {
      if (this.contactlist.length == 0) {
        alert("학교를 먼저 검색해주세요.");
        return;
      }
      let mail_contacts = this.contactlist.filter((a) => a.mailCheck == true);
      let tel_contacts = this.contactlist.filter((a) => a.telCheck == true);

      if (mail_contacts.length == 0 && tel_contacts.length == 0) {
        alert("연락처를 종류별로 하나 이상 선택해주세요");
        return;
      }

      let estimate_list = this.estimatelist.filter((a) => a.isSelected == true);

      if (estimate_list.length == 0) {
        alert("견적서를 선택해주세요.");
        return;
      }

      if (estimate_list.length > 1) {
        alert("견적서를 하나만 선택해주세요.");
        return;
      }

      let estimate_url = `https://argongadmin.argong.io/estimate/download?url=https://kr.object.gov-ncloudstorage.com/dn-bucket/estimate/${this.schoolcode}/${estimate_list[0].idx}.pdf&schoolName=${this.searchschool}`;

      for (let i = 0; i < this.contactlist.length; i++) {
        if (!this.contactlist[i].mailCheck) {
          continue;
        } else {
          const maildata = {
            email: this.contactlist[i].mail,
            name: this.contactlist[i].name,
            remain: this.getremain(
              parseInt(this.realmoney.replace(/,/g, "")),
              this.real_money_per
            ),
            estimate_url,
          };

          const res = await this.$store.dispatch(
            "SEND_ESTIMATE_MAIL",
            maildata
          );

          if (res == "good") {
            this.count.mail.good.push({
              name: this.contactlist[i].name,
              mail: this.contactlist[i].mail,
            });
          }

          if (res == "error") {
            this.count.mail.bad.push(this.contactlist[i].mail);
          }
        }
      }

      for (let i = 0; i < this.contactlist.length; i++) {
        if (!this.contactlist[i].telCheck) {
          continue;
        } else {
          const smsdata = {
            tel: this.contactlist[i].tel,
          };

          const res = await this.$store.dispatch("SEND_ESTIMATE_SMS", smsdata);

          if (res == "good") {
            this.count.sms.good.push({
              name: this.contactlist[i].name,
              tel: this.contactlist[i].tel,
            });
          }

          if (res == "error") {
            this.count.sms.bad.push(this.contactlist[i].tel);
          }
        }
      }

      const res3 = await changeschoolpaystatus({
        schoolCode: this.schoolcode,
        payStatus: "견적발급",
      });
      if (res3.data.data) {
        alert(
          `메일 전송 성공 : ${this.count.mail.good.length}건\n메일 전송 실패 : ${this.count.mail.bad.length}건\n문자 전송 성공 : ${this.count.sms.good.length}건\n문자 전송 실패 : ${this.count.sms.bad.length}건`
        );
      }

      const toContactMailList = this.count.mail.good;
      const toContactTelList = this.count.sms.good;
      const idx = estimate_list[0].idx;
      const schoolCode = this.schoolcode;

      const senddata = { schoolCode, idx, toContactMailList, toContactTelList };

      console.log(senddata);

      this.count.mail.good = [];
      this.count.mail.bad = [];
      this.count.sms.good = [];
      this.count.sms.bad = [];

      const res2 = await this.$store.dispatch("SEND_ESTIMATE", senddata);

      if (res2.data.result) {
        alert("견적 발송 완료 처리되었습니다.");
      }

      if (this.estimate_idx) {
        if (window.confirm("견적을 초기화하시겠습니까?")) {
          this.schoolname = "";
          this.plan = "";
          this.estimateinit();
        }
      }
    },
    // async schoolsearch() {
    //   const schoolname = this.searchschool;
    //   const schoolcode = this.searchdata[schoolname];
    //   const res = await this.$store.dispatch("GET_SCHOOL_CONTACT", schoolcode);
    //   console.log(res);
    //   if (res) {
    //     this.contactlist = res;
    //   } else {
    //     alert("학교 정보가 존재하지 않습니다.");
    //     this.searchschool = "";
    //   }
    // },
    async schoolsearch() {
      if (this.searchdata[this.searchschool]) {
        let regex = /^[a-zA-Z0-9]*$/;

        if (regex.test(this.searchschool)) {
          this.schoolcode = this.searchschool;
          this.searchschool = this.searchdata[this.searchschool];
        } else {
          this.schoolcode = this.searchdata[this.searchschool];
        }

        alert(
          `학교 이름 : ${this.searchschool}\n학교 코드 : ${this.schoolcode}\n설정되었습니다.`
        );

        this.estimatelist = await this.$store.dispatch(
          "GET_ESTIMATE",
          this.schoolcode
        );

        for (let i = 0; i < this.estimatelist.length; i++) {
          this.estimatelist[i].isSelected = false;
        }

        this.contactlist = await this.$store.dispatch(
          "GET_SCHOOL_CONTACT",
          this.schoolcode
        );

        return;
      } else {
        this.searchschool = "";
        this.schoolcode = "";
        alert("학교 정보가 존재하지 않습니다.");
      }
    },
    getplanstring(str) {
      if (str == "premium") {
        return "프리미엄";
      } else if (str == "general") {
        return "일반";
      } else {
        return "-";
      }
    },
    getlocalestring(num) {
      return num.toLocaleString();
    },
    getestimatetime(arr) {
      return arr.join("\n");
    },
  },
};
</script>

<style scoped>
.inputTitle {
  font-family: notosanslight;
  font-weight: bold;
}
.optioninput {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 250px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  border-radius: 7px;
  background-image: url("../../assets/image/searchicon.png");
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 221px;
}

.optioninputlong {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 550px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  border-radius: 7px;
  background-image: url("../../assets/image/searchicon.png");
  background-position: right;
  background-repeat: no-repeat;
  background-position-x: 520px;
}

.optioninputnoicon {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 250px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  border-radius: 7px;
}

.fixmoney {
  padding: 10px;
  border: 1px solid black;
  font-size: 14px;
  width: 300px;
  margin-top: 3px;
  font-weight: bold;
  font-family: notosanslight;
  background-color: rgb(230, 230, 230);
  margin-top: 5px;
  border-radius: 5px;
  float: left;
}

.moneysentence {
  font-family: notosanslight;
  color: gray;
  font-size: 15px;
  float: left;
  margin-top: 3px;
  margin-left: 10px;
  font-weight: bold;
}

.planselect {
  border: 1px solid black;
  width: 250px;
  margin-top: 3px;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-family: notosanslight;
  font-weight: bold;
  font-size: 14px;
  background: url("../../assets/image/vector.png") calc(100% - 15px) center
    no-repeat;
  background-size: 17px;
}

.planselect option[value=""][disabled] {
  display: none;
}

.planselect:required:invalid {
  color: gray;
}

.tabletd {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  padding: 8px;
  font-size: 13px;
  background-color: rgb(240, 240, 240);
}

.tabletd2 {
  border: 1px solid #7f7f7f;
  font-family: notosanslight;
  font-weight: bold;
  padding: 10px;
  font-size: 14px;
}
</style>